import React, { useState, useRef, useEffect } from "react";

export default function Dropdown(props) {
  const [focus, setFocus] = useState(false);
  const ref = useRef()

  useOnClickOutside(ref, () => setFocus(false));

  return (
    <div className={ props.classNames } ref={ ref }>
      <p className="selected" onClick={ () => { setFocus(true) } }>{ props.selected }</p>
      <div className={ focus ? "month-dropdown dates-dropdown open" : "month-dropdown dates-dropdown" }>
        { props.children }
      </div>
    </div>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}