import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addAction, editAction, copyAction } from '../../store/actions/actions';
import Button from "../Button";
import _, { conformsTo, forEach, map } from 'lodash';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg'
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import SearchInput, { createFilter } from '../SearchInput/SearchInput'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import pl from 'date-fns/locale/pl';
import { fetchLocations, resetLocations } from '../../store/actions/locations';
import { fetchTerritories } from '../../store/actions/territories';
import { setDate, fetchPersonnel } from '../../store/actions/personnel';
import { getUser } from '../../store/reducers/user';
import { getActions } from '../../store/reducers/actions';
import { getTerritories } from '../../store/reducers/territories';
import { getPersonnel } from '../../store/reducers/personnel';
import { getLocations } from '../../store/reducers/locations';
import { getEvents } from '../../store/reducers/events';
import { fetchEvents } from '../../store/actions/events';
registerLocale('pl', pl)

function AddEditAction({ ident, hide, range }) {
  const dispatch = useDispatch();
  const actions = useSelector(getActions);
  const territories = useSelector(getTerritories);
  const personnel = useSelector(getPersonnel);
  const locations = useSelector(getLocations);
  const events = useSelector(getEvents);
  const user = useSelector(getUser)
  const defaultAction = {
    new: true,
    ident: "",
    event: { name: "" },
    actionPoints: [],
    users: [],
    territory: {
      uuid: user.territories[0].uuid,
      ident: ''
    },
    area: {
      uuid: user.areas[0].uuid
    },
    excerpt: "",
    description: ""
  }
  const [action, setAction] = useState(_.find(actions.list, ['ident', ident]) || defaultAction)
  const [activeInput, setActiveInput] = useState("actionName");
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState([]);
  const [selectedTerritory, setSelectedTerritory] = useState(null);
  const [day, setDay] = useState(action.since ? moment(action.since.date).format('D') : '');
  const [month, setMonth] = useState(action.since ? moment(action.since.date).format('M') : '');
  const [year, setYear] = useState(action.since ? moment(action.since.date).format('Y') : '');
  const [timestart, setTimestart] = useState(action.since ? moment(action.since.date).format('HH:mm') : '');
  const [timeend, setTimeend] = useState(action.since ? moment(action.until.date).format('HH:mm') : '');
  const [locationFilter, setLocationFilter] = useState("");
  const [personnelFilter, setPersonnelFilter] = useState("");
  const [eventFilter, setEventFilter] = useState("");

  useEffect(() => {
    if (action?.event?.uuid) {
      console.log(action)
      dispatch(fetchLocations(action.event.uuid));
    } else {
      dispatch(resetLocations());
    }
    dispatch(fetchTerritories(action.event.uuid));
  }, [])
  useEffect(() => {
    console.log(action)
  }, [action])

  const handleInputChange = (e) => {
    const target = e.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    setAction({ ...action, name: value })
  }

  const selectEvent = (event, index) => {
    setAction({ ...action, event: { uuid: event.uuid, name: event.name } })
    dispatch(fetchLocations(event.uuid, selectedTerritory))
  }

  const selectTerritory = (territory, index) => {
    setAction({ ...action, territory: { uuid: territory.uuid, ident: territory.ident } })
    setSelectedTerritory(territory.uuid);
    dispatch(fetchLocations(selectedEvent, territory.uuid));
    dispatch(fetchPersonnel(actions.month, territory.uuid));
  }

  const selectLocation = (location, index) => {
    let actionPoints = action.actionPoints;
    if (action?.actionPoints?.length > 0) {
      let exists = false;
      _.each(actionPoints, (item) => {
        if (location.uuid === item.point.uuid) {
          item.trash = !item.trash;
          exists = true;
        }
      })

      if  (!exists) {
        actionPoints.push({ trash: false, point: { uuid: location.uuid }, address: location.address, name: location.name, ident: location.ident })
      }
    } else {
      actionPoints = [{ trash: false, point: { uuid: location.uuid }, address: location.address, name: location.name, ident: location.ident }];
    }

    setAction({ ...action, actionPoints: actionPoints })
  }

  const selectPersonnel = (personnel, index) => {
    let users = action?.users
    if (action?.users && action?.users?.length > 0) {
      let exists = false;
      _.each(users, (item) => {
        if (personnel.uuid === item.uuid) {
          item.trash = !item.trash;
          exists = true;
        } else {
          item.trash = true;
        }
      })
      if (!exists) {
        users.push({ trash: false, uuid: personnel.uuid, firstname: personnel.firstname, lastname: personnel.lastname, ident: personnel.ident })
      }
    } else {
      users = [{ trash: false, uuid: personnel.uuid, firstname: personnel.firstname, lastname: personnel.lastname, ident: personnel.ident }];
    }

    setAction({ ...action, users: users })
  }

  const handleDateChange = (e) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let day = day;
    let month = month;
    let year = year;
    if (name === 'day') day = value;
    if (name === 'month') month = value;
    if (name === 'year') year = value;
    if (moment(month + '.' + day + '.' + year).isValid()) {
      setDay(day);
      setMonth(month);
      setYear(year);
      setAction({
        ...action,
        since: { date: moment(month + '.' + day + '.' + year + ' ' + timestart).format("YYYY-MM-DD HH:mm:ss") },
        until: { date: moment(month + '.' + day + '.' + year + ' ' + timeend).format("YYYY-MM-DD HH:mm:ss") }
      })
      dispatch(setDate(month + '.' + day + '.' + year))
    } else {
      setDay(day);
      setMonth(month);
      setYear(year);
    }
  }

  const handleTimeChange = (e) => {
    const target = e.target;
    let value = target.value;
    let formatedValue = value;
    let name = target.name;
    if (value.indexOf(':') === -1) {
      formatedValue += ':00';
    }
    if (name === 'timestart') {
      setAction({
        ...action,
        since: { date: moment(year + '-' + month + '-' + day + ' ' + formatedValue).format("YYYY-MM-DD HH:mm:ss") }
      })
      setTimestart(value);
    } else {
      setAction({
        ...action,
        until: { date: moment(year + '-' + month + '-' + day + ' ' + formatedValue).format("YYYY-MM-DD HH:mm:ss") }
      })
      setTimeend(value);
    }
  }

  const isActiveLocation = (action, location) => {
    let cl = ''
    if (action.actionPoints != null && action.actionPoints.length > 0) {
      for (let i = 0; i < action.actionPoints.length; i++) {
        if (action.actionPoints[i].point.uuid === location.uuid && action.actionPoints[i].trash === false) {
          cl = 'active'
        }
      }
    } 

    return cl;
  }

  let inputBox = null;

  const icons = {
    'active': <ActiveIcon />,
    'new': <NewIcon />,
    'canceled': <CanceledIcon />,
    'ended': <EndedIcon />,
    'accepted': <ApprovedIcon />
  }

  const CalendarInput = ({ value, onClick }) => (
    <CalendarIcon className="icon-calendar" onClick={onClick} />
  );

  switch (activeInput) {
    case "actionName":
      inputBox = (
        <div className="input-box input-box-name">
          <h3>Dodaj nazwę akcji:</h3>
          <input type="text" name="name" value={action.name} onChange={(e) => { handleInputChange(e) }} placeholder="Nazwa akcji" />
          <h3>Ostatnio używane nazwy:</h3>
          <div className="buttons">
            <Button action={() => { setActiveInput(user.role.uuid === "ASM" || user.role.uuid === "HSADM" ? 'territory' : 'eventName') }}>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "territory":
      const filteredTerritories = territories?.list?.filter(createFilter(eventFilter, ['name']))

      inputBox = (
        <div className="input-box input-box-territory">
          <h3>Wybierz z listy:</h3>
          <SearchInput className="search-input" placeholder="Wyszukaj terytorium..." onChange={(filter) => {
            this.setState({ eventFilter: filter })
          }} />
          <ul>
            {filteredTerritories.map((item, i) => {
              return <li key={i} className={action.territory.uuid === item.uuid ? 'active' : ''} onClick={() => { selectTerritory(item, i) }}>{item.ident}</li>
            })}
          </ul>
          <div className="buttons">
            <Button action={() => { setActiveInput("eventName") }}>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "eventName":
      const filteredEvents = events?.list?.filter(createFilter(eventFilter, ['name']))

      inputBox = (
        <div className="input-box input-box-event">
          <h3>Wybierz z listy:</h3>
          <SearchInput className="search-input" placeholder="Wyszukaj event..." onChange={(filter) => {
            setEventFilter(filter)
          }} />
          <ul>
            {filteredEvents.map((item, i) => {
              return <li key={i} className={action.event.uuid === item.uuid ? 'active' : ''} onClick={() => { selectEvent(item, i) }}>{item.name}</li>
            })}
          </ul>
          <div className="buttons">
            <Button action={() => { setActiveInput('location') }}>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "location":
      const filteredLocations = locations?.list?.filter(createFilter(locationFilter, ['name', 'address.streetAddress']))

      inputBox = (
        <div className="input-box input-box-location">
          <h3>Wybierz z listy:</h3>
          <SearchInput className="search-input" placeholder="Wyszukaj lokalizację..." onChange={(filter) => {
            setLocationFilter(filter)
          }} />
          <ul>
            {filteredLocations.map((item, i) => {
              return <li key={i} className={isActiveLocation(action,item)} onClick={() => { selectLocation(item, i) }}>{item.name + ', ' + item.address.streetAddress + ', ' + item.address.cityName}</li>
            })}
          </ul>
          <div className="buttons">
            <Button action={() => { setActiveInput("date") }}>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "date":
      const date = day ? moment(day + "-" + month + "-" + year, "DD-MM-YYYY").toDate() : moment().toDate()
      inputBox = (
        <div className="input-box input-box-date">
          <h3>Wybierz dzień:</h3>
          <div className="date-inputs">
            <input onChange={(e) => handleDateChange(e)} value={day} name="day" placeholder="dzień" />
            <input onChange={(e) => handleDateChange(e)} value={month} name="month" placeholder="miesiąc" />
            <input onChange={(e) => handleDateChange(e)} value={year} name="year" placeholder="rok" />
            <DatePicker
              selected={date}
              locale="pl"
              onChange={(date) => {
                let dt = moment(date);
                setDay(dt.format("DD"));
                setMonth(dt.format("MM"));
                setYear(dt.format("YYYY"));
                setAction({
                  ...action,
                  since: { date: moment(dt.format("YYYY-MM-DD") + ' ' + timestart).format("YYYY-MM-DD HH:mm:ss") },
                  until: { date: moment(dt.format("YYYY-MM-DD") + ' ' + timeend).format("YYYY-MM-DD HH:mm:ss") }
                })
                dispatch(setDate(dt.format("YYYY-MM-DD")));
              }}
              customInput={<CalendarInput />}
            />
          </div>
          <div className="buttons">
            <Button action={() => { setActiveInput("time") }}>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "time":
      inputBox = (
        <div className="input-box input-box-time">
          <h3>Wybierz zakres dostępności:</h3>
          <div className="time-inputs">
            <span>od</span>
            <input onChange={(e) => handleTimeChange(e)} value={timestart} name="timestart" placeholder="8:00" />
            <span>do</span>
            <input onChange={(e) => handleTimeChange(e)} value={timeend} name="timeend" placeholder="21:00" />
          </div>
          <div className="buttons">
            <Button action={() => { setActiveInput("personnel") }}>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "personnel":
      const filteredPersonnel = personnel.list ? personnel.list.filter(createFilter(personnelFilter, ['firstname', 'lastname'])) : [];

      inputBox = (
        <div className="input-box input-box-personnel">
          <h3>Wybierz z listy:</h3>
          <SearchInput className="search-input" placeholder="Wyszukaj imię i nazwisko..." onChange={(filter) => {
            setPersonnelFilter(filter)
          }} />
          <ul>
            {filteredPersonnel.map((item, key) => {
              let cl = '';
              let availableThisDay = '';
              if (action.since && action.until && item.availability != null) {
                _.each(item.availability, (av, index) => {
                  if (moment(av.since.date).isSame(action.since.date, 'day')) {
                    availableThisDay = moment(av.since.date).format('HH:mm') + ' - ' + moment(av.until.date).format('HH:mm');

                    if (moment(av.since.date).isSameOrBefore(action.since.date) && (moment(av.until.date).isSameOrAfter(action.until.date))) {
                      cl = 'available';
                    }
                  }
                })
              }

              if (item.actions != null && item.actions.length > 0) {
                cl = cl + ' taken';
              }

              let check = false;
              if (action.users != null && action.users.length > 0) {
                if (action.users[0].uuid === item.uuid && !action.users[0].trash) {
                  check = true;
                }
                if (action.users[1] && action.users[1].uuid === item.uuid && !action.users[1].trash) {
                  check = true;
                }
              }
              if (item.role.uuid === 'HST') {
                return (
                  <li key={"personnel-" + key} className={check ? cl + ' hostess-name active' : 'hostess-name ' + cl} onClick={() => { selectPersonnel(item, key) }}><span>{item.firstname + ' ' + item.lastname}</span><span>{availableThisDay}</span></li>
                )
              }
            })
            }
          </ul>
          <div className="buttons">
            <Button
              action={() => {
                console.log(action, action.new)
                action.new ?
                  dispatch(addAction(action, range)) :
                  dispatch(editAction(action, range));
                hide();
              }
              }>Zapisz</Button>
          </div>
        </div>
      )
      break;
    default:
      break;
  }

  const territory = user.role.uuid === "ASM" || user.role.uuid === "HSADM" ? <td onClick={() => { setActiveInput("territory") }}>{action.territory.ident === '' ? 'Terytorium' : action.territory.ident}</td> : null;

  return (
    <div className="add-edit-holder">
      <table className="add-edit-table">
        <tbody>
          <tr className={"action-row new"}>
            <td className="status-cell" tabIndex="0" onBlur={() => { this.setState({ statusSelect: false }) }}>
              <div className={"status-icon"} onClick={() => { this.setState({ statusSelect: true }) }}>{icons['new']}</div>
            </td>
            <td onClick={() => { setActiveInput("actionName") }} className="action-name"><span>{action.name === '' ? 'Nazwa akcji' : action.name}</span></td>
            <td>{action.ident}</td>
            {territory}
            <td onClick={() => { setActiveInput("eventName") }}>{action.event.name === '' ? 'Nazwa eventu' : action.event.name}</td>
            <td onClick={() => { setActiveInput("location") }}>
              {action.actionPoints ? action.actionPoints.length > 0 ?
                action.actionPoints.map((item, key) => {
                  if (!item.trash) {
                    return <p key={key}>{ item.name }</p>
                  }
                }) : 'Lokacja'
                : 'Lokacja'}
            </td>
            <td onClick={() => { setActiveInput("date") }}>{action.since ? moment(action.since.date).format("D/M/Y") : "Data"}</td>
            <td onClick={() => { setActiveInput("time") }}>{action.since && action.until ? moment(action.since.date).format("H:mm") + ' - ' + moment(action.until.date).format("H:mm") : "Zakres godz."}</td>
            <td onClick={() => { setActiveInput("personnel") }}>
              {action.users ? action.users.length > 0 ?
                action.users.map((item, key) => {
                  if (!item.trash) {
                    return <p key={key}>{item.firstname + ' ' + item.lastname}</p>
                  }
                }) : 'Personel'
                : 'Personel'}
            </td>
            <td>
              <div className="buttons">
                <Button
                  action={() => {
                    console.log(action, action.new)
                    action.new ?
                      dispatch(addAction(action, range)) :
                      dispatch(editAction(action, range));
                    hide();
                  }
                  }>Zapisz</Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {inputBox}
    </div>
  );
}

export default AddEditAction;