import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';
import Button from '../Button';
import PopupModal from '../modals/PopupModal';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getActions } from '../../store/reducers/actions';


function Calendar({person}) {
  const actions = useSelector(getActions)
  const [availability, setAvailability] = useState([{start: '09:00', end: '18:00'}]);
  const [hourStart, setHourStart] = useState(9);
  const [hourEnd, setHourEnd] = useState(16);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [popup, setPopup] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("M"));
  const [dropdown, setDropdown] = useState(false);
  const hours = ['06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00']

  moment().locale('pl');

  useEffect(()=>{
    // const today = moment()
    // const data = {
    //   "availability": {
    //       "since": "2020-" + today.format("M") + "-" + today.format("D"),
    //       "until": "2020-" + today.add(2, 'months').format("M") + "-" + today.add(2, 'months').daysInMonth()
    //   }
    // }
    //uploadAvailability(data)
  }, [])

  const handleAvailability = () => {
    // const since = selectedColumn.set("hour", hourStart).format("YYYY-MM-DD HH:mm:ss")
    // const until = selectedColumn.set("hour", hourEnd).format("YYYY-MM-DD HH:mm:ss")
    
    // const data = {
    //   "availability": {
    //       "since": since,
    //       "until": until
    //   }
    // }

    //createAvailability(data)
  }

  const openPopup = (hour, date) => {
    setHourStart(moment('2020-10-10 '+hour).format('H'));
    setHourEnd(moment('2020-10-10 '+hour).add(1,'hours').format('H'));
    setSelectedColumn(date);
    setPopup(true);
  }

  const currentDay = moment().format("D")
  const currentMonth = moment().format("M")
  const currentMonthDays = moment().daysInMonth()
  const selectedMonthDays = selectedMonth !== currentMonth ? moment().set('month', selectedMonth-1).daysInMonth() : currentMonthDays
  let days = []
  let columns = []

  for (let i = currentMonth !== selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
    const day = moment( selectedMonth + "/" + i, 'MM/DD')

    const item = (
      <div key={ i } className={ i === currentDay ? "calendar-day today" : "calendar-day" }>
        <div className="day-name">{ day.format("dd") }</div><div className="day-no">{ day.format("D")  }</div>
      </div>
    )
    days.push(item)
  }

  for (let i = currentMonth !== selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
    const day = moment( selectedMonth + "/" + i, 'MM/DD')
    const availabilityItems = _.filter(person.availability, (item) => {
      if(moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
        return item
      }
    })

    const actionItems = _.filter(actions.list, (item) => {
      if(moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
        return item
      }
    })

    const item = (
      <div key={ i } className="calendar-column">
        { hours.map((item,key)=>{
          return <div key={ 'cell-'+key } className="hour-cell" ></div>
        })}
        { availabilityItems.map((avail, k) => {
          const diff = moment(avail.since.date).diff(moment(avail.until.date), 'hours');
          return <div className="availability" style={{ height: (-diff)*40-3, top: (((moment(avail.since.date).format("H")-6)*40)-3) + "px" }}><span>Dostępność</span></div>
        }) }
        { actionItems.map((action, k) => {
          const diff = moment(action.since.date).diff(moment(action.until.date), 'hours');
          return <div className="event" style={{ height: (-diff)*40-3, top: (((moment(action.since.date).format("H")-6)*40)-3) + "px" }}><span>{ action.name }</span></div>
        }) }
      </div>
    )
    columns.push(item)
  }

  return (
    <section className="calendar">
      <div className="calendar-hour-labels">
        { hours.map((item,key)=>{
          return <div key={ key } className="hour-label">{ item }</div>
        })}
      </div>
      <div className="calendar-columns">
        <div className="calendar-header">
          { days }
        </div>
        <div className="calendar-columns-inner">
          { columns }
        </div>
      </div>
      <PopupModal open={ popup } hidePopup={ ()=>{ setPopup(false) } }>
        <div className="popup-inner">
          <h3>Podaj zakres dostępności:</h3>
          <div className="hours-inputs">
            <label>od</label>
            <input type="number" min="6" max="22" onChange={ (e)=>{ setHourStart(e.target.value) } } value={ hourStart} />
            <label>do</label>
            <input type="number" min="6" max="22" onChange={ (e)=>{ setHourEnd(e.target.value) } } value={ hourEnd} />
          </div>
          <Button action={ ()=>{ setPopup(false); handleAvailability(); } }>Gotowe</Button>
        </div>
      </PopupModal>
    </section>
  );
}

export default Calendar;