import { 
    LOGIN,
    LOGOUT,
    REMIND,
    REMIND_OTP,
    SESSION,
    CHANGE_PASS,
    ADD_TOAST
  } from './types';
import { API_URL, appFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';
import { uploadActions, webformAction } from './actions';
import { fetchPersonnel } from './personnel';
import { fetchEvents } from './events';
import { clearState } from '../../localStorage';
  
export function login(data, navigate) {
  return (dispatch, getState) => { 
      appFetch("POST", API_URL, "/auth/login", data, false, (result) => {
          if(result.status && result.status.success) {
            if (result.data.user.role.uuid != "HST") {
              localStorage.setItem("token", JSON.stringify(result.data.token));
              dispatch({ type: LOGIN, payload: { token: result.data.token, user: result.data.user } });
              //dispatch(uploadActions())
              dispatch(webformAction())
              dispatch(fetchEvents())
              dispatch(fetchPersonnel())
              navigate("/actions", {replace: true})
              dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zalogowano" } });
            } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: "Brak uprawnień" } });
            }
          } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function checkSession(navigate, token) {
  return (dispatch, getState) => { 
      appFetch("POST", API_URL, "/auth/session", false, token.uuid, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: SESSION, payload: { token: result.data.token, user: result.data.user } });
              //dispatch(uploadActions())
              dispatch(webformAction())
              dispatch(fetchEvents())
              dispatch(fetchPersonnel())
              navigate("/actions", {replace: true})
          } else {
              dispatch({ type: SESSION, payload: { token: null, user: null } });
              //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
              navigate("/", {replace: true})
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}
  
export function logout(navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/auth/logout", false, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
          if(result.status && result.status.success) {
              clearState()
              dispatch({ type: LOGOUT, payload: null });
              navigate("/", {replace: true})
          } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
              navigate("/", {replace: true})
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function remind(data, navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/reset-token", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REMIND, payload: data.username });
          navigate("/remind/code", {replace: true})
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          navigate("/remind", {replace: true})
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function changePass(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/update-password", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: CHANGE_PASS, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zmieniono hasło." } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function remindCode(data, navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/reset-password", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REMIND_OTP, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Pomyślnie zmieniono hasło!" } });
          navigate("/", {replace: true})
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
          navigate("/remind", {replace: true})
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}
