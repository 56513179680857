import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../store/actions/user';
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'
import Button from './Button'

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else if (pass === "") {
      setError("Wpisz hasło")
    } else {
      setError("")
      handleLogin()
    }
  }

  const handleLogin = () => {
    const data = {
      username: email,
      password: pass
    }
    
    dispatch(login(data, navigate))
  }

  return (
    <section className="login-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
  			<h2>Zaloguj się</h2>
  			<p>Podaj nazwę użytkownika i hasło</p>
  			<form>
          <div className="center">
            <input type="email" placeholder="nazwa użytkownika" name="username" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          <div className="center">
            <input type="password" placeholder="hasło" name="password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
          </div>
          <div className="remind">
            Nie pamiętasz hasła? > <NavLink to="/remind">Zresetuj hasło</NavLink>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="center">
            <Button className="btn" action={ (e) => { validate(e) } }>Dalej</Button>
          </div>
        </form>
  		</div>
    </section>
  );
}

export default LoginPage;