import React, { useState, useRef, useEffect } from "react";
import SearchInput, {createFilter} from '../components/SearchInput/SearchInput'

export default function SearchField(props) {
  const [focus, setFocus] = useState(false);
  const ref = useRef()

  useOnClickOutside(ref, () => setFocus(false));

  return (
    <div className="input-holder" ref={ref}>
      <SearchInput 
        value={ props.value }
        onFocus={ () => setFocus(true) }
        className="search-input" placeholder={ props.placeholder } onChange={ props.onChangeAction } />
        <div className={ focus ? "list-holder active" : "list-holder" }>
          { props.children }
        </div>
    </div>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}