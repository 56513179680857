import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg';
import { remindCode } from '../store/actions/user';
import { getRemindEmail } from "../store/reducers/user";

function RemindCodePage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const remindEmail = useSelector(getRemindEmail)


  let validate = (e) => {
    e.preventDefault()

    if (pass === "") {
      setError("Wpisz nowe hasło")
    } else if (code === "") {
      setError("Wpisz kod")
    } else {
      setError("")
      dispatch(remindCode({username: remindEmail, otp: code, password: pass}, navigate))
    }
  }

  return (
    <section className="remind-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
			<h2>Nowe hasło</h2>
			<p>Podaj otrzymany kod i nowe hasło</p>
			<form autocomplete="off">
        <div className="center">
            <input type="text" placeholder="kod" name="otp" autocomplete="otp" value={ code } onChange={ (e) => { setCode(e.target.value) } }/>
        </div>
        <div className="center">
            <input type="password" placeholder="nowe hasło" name="newpass" autocomplete="new-password" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
        </div>
        { error !== "" ? <p className="error">{ error }</p> : null }
        <div className="center">
            <button className="btn" onClick={ (e) => { validate(e) } }>Dalej</button>
        </div>
    </form>
		</div>
    </section>
  );
}

export default RemindCodePage;