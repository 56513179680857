import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../store/actions/user';
import { ReactComponent as IconEvents } from '../../img/menu-eventy.svg'
import { ReactComponent as IconActions } from '../../img/menu-akcje.svg'
import { ReactComponent as IconReports } from '../../img/menu-raporty.svg'
import { ReactComponent as IconPersonnel } from '../../img/menu-personel.svg'
import { ReactComponent as IconAvatar } from '../../img/menu-avatar.svg'
import { ReactComponent as IconClose } from '../../img/menu-close.svg'
import { ReactComponent as IconAccount } from '../../img/menu-twoje-konto.svg'
import { ReactComponent as IconLogout } from '../../img/menu-wyloguj.svg'
import { ReactComponent as IconRegions } from '../../img/menu-regionalizacja.svg'
import { ReactComponent as IconKody } from '../../img/menu-kody.svg'
import { ReactComponent as IconWebform } from '../../img/menu-webform.svg'
import { getUser } from '../store/reducers/user';

function Navigation({open, close}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  return (
    <div className={open ? "nav active" : "nav" }>
      <div className="close" onClick={ close }><IconClose /></div>
      <div className="profile-section">
        {/*<IconAvatar className="avatar" />*/}
        <p>Witaj<br/><span className="profile-name">{ user ? user.firstname : "" }</span></p>
      </div>
      { user && user.role.uuid === "HSADM" ?
        <div className="nav-links">
          <NavLink className="nav-item" to="/actions" onClick={ close }><IconActions /><span>Akcje</span></NavLink>
          <NavLink className="nav-item" to="/reports" onClick={ close }><IconReports /><span>Raporty</span></NavLink>
          <NavLink className="nav-item" to="/webfrom" onClick={ close }><IconWebform /><span>Webform</span></NavLink>
        </div>
      : <div className="nav-links">
        { user && user.role.uuid === "ADMIN" ?
          <NavLink className="nav-item" to="/events" onClick={ close }><IconEvents /><span>Eventy</span></NavLink> : null }
          <NavLink className="nav-item" to="/actions" onClick={ close }><IconActions /><span>Akcje</span></NavLink>
          <NavLink className="nav-item" to="/reports" onClick={ close }><IconReports /><span>Raporty</span></NavLink>
          <NavLink className="nav-item" to="/personnel" onClick={ close }><IconPersonnel /><span>Personel</span></NavLink>
          <NavLink className="nav-item" to="/webform" onClick={ close }><IconWebform /><span>Webform</span></NavLink>
        { user && user.role.uuid === "ADMIN" ?
          <>
            <NavLink className="nav-item" to="/regions" onClick={ close }><IconRegions /><span>Regionalizacja</span></NavLink> 
            <NavLink className="nav-item" to="/kody" onClick={ close }><IconKody /><span>Kody</span></NavLink>
          </> : null } 
        </div> }
      <div className="nav-footer">
        <NavLink className="nav-item" to="/twoje-konto" onClick={ close }><IconAccount /><span>Twoje Konto</span></NavLink>
        <span className="nav-item" onClick={ () => { close(); dispatch(logout(navigate)) } }><IconLogout /><span>Wyloguj</span></span>
      </div>
    </div>
  );
}

export default Navigation;