import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addAction, editAction, copyAction } from '../../store/actions/actions';
import Button from "../Button";
import _ from 'lodash';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as CheckboxMarkedIcon } from '../../../img/checkbox-marked.svg';
import { ReactComponent as CheckboxIcon } from '../../../img/checkbox.svg';
import { getActions } from '../../store/reducers/actions';
import { getPersonnel } from '../../store/reducers/personnel';
import moment from 'moment';

function CopyAction({ ident, hide, range }) {
  const dispatch = useDispatch()
  const actions = useSelector(getActions);
  const personnel = useSelector(getPersonnel);
  let action = _.find(actions.list, ['ident', ident]);
  // if (action === undefined) {
  //   action = this.props.actions.default;
  // }
  const [copyVol, setCopyVol] = useState(1);
  const [copySelected, setCopySelected] = useState([true, true, true, true, true, true])
  const [activeInput, setActiveInput] = useState("actionName")

  const toggleCopy = (index) => {
    setCopySelected(prev => prev.map((element, index_) => index_ === index ? !element : element ))
  }

  const copyItem = (action, copy) => {
    for (let i = 0; i < copyVol; i++) {
      dispatch(copyAction(action, copy));
    }
  }

  const copyAction_ = () => {
    let actionTemp = { ...action };
    if (!copySelected[0]) {
      actionTemp.name = "";
    }
    if (!copySelected[2]) {
      actionTemp.actionPoints = actionTemp.actionPoints ?? [];
    }
    if (!copySelected[3] || !copySelected[4]) {
      actionTemp.since = null;
      actionTemp.until = null;
    }
    if (!copySelected[5]) {
      actionTemp.users = [];
    }
    console.log(actionTemp)
    dispatch(copyAction(actionTemp, copyVol, range));
  }

  return (
    <div className="add-edit-holder">
      <table className="add-edit-table">
        <tbody>
          <tr className={"action-row new"}>
            <td className="status-cell"><div className={"status-icon"}><NewIcon /></div></td>
            <td onClick={() => { setActiveInput("actionName") }} className="action-name">
              <span>{action?.name === '' ? 'Nazwa akcji' : action?.name}</span>
              <div onClick={() => { toggleCopy(0) }} className={copySelected[0] ? "copy-select" : "copy-select active"}>{copySelected[0] ? <CheckboxMarkedIcon /> : <CheckboxIcon />}</div>
            </td>
            <td>{action?.ident}</td>
            <td onClick={() => { setActiveInput("eventName") }}>
              {action?.event.name === '' ? 'Nazwa eventu' : action?.event.name}
            </td>
            <td>
              {action?.actionPoints ? action?.actionPoints.length > 0 ?
                action?.actionPoints.map((item, key) => {
                  return <p key={key}>{item.name + ', ' + item.address.streetAddress + ' ' + item.address.streetNumber + ', ' + item.address.cityName + ', ID: ' + item.ident}</p>
                }) : 'Lokacja'
                : 'Lokacja'
              }
              <div onClick={() => { toggleCopy(2) }} className={copySelected[2] ? "copy-select" : "copy-select active"}>{copySelected[2] ? <CheckboxMarkedIcon /> : <CheckboxIcon />}</div>
            </td>
            <td onClick={() => { setActiveInput("date") }}>{action?.since ? moment(action?.since.date).format("D/M/Y") : "Data"}
              <div onClick={() => { toggleCopy(3) }} className={copySelected[3] ? "copy-select" : "copy-select active"}>{copySelected[3] ? <CheckboxMarkedIcon /> : <CheckboxIcon />}</div>
            </td>
            <td onClick={() => { setActiveInput("time") }}>{action?.since && action?.until ? moment(action?.since.date).format("H:mm") + ' - ' + moment(action?.until.date).format("H:mm") : "Zakres godz."}
              <div onClick={() => { toggleCopy(4) }} className={copySelected[4] ? "copy-select" : "copy-select active"}>{copySelected[4] ? <CheckboxMarkedIcon /> : <CheckboxIcon />}</div>
            </td>
            <td onClick={() => { setActiveInput("personnel") }}>
              {action?.users ? action?.users.length > 0 ?
                action?.users.map((item, key) => {
                  return <p key={key}>{item.firstname + ' ' + item.lastname}</p>
                }) : 'Personel'
                : 'Personel'}
              <div onClick={() => { toggleCopy(5) }} className={copySelected[5] ? "copy-select" : "copy-select active"}>{copySelected[5] ? <CheckboxMarkedIcon /> : <CheckboxIcon />}</div>
            </td>
            <td>
              <div className="buttons">
                <div>
                  <p>Ilość kopii:</p>
                  <input type="number" min="1" max="100" value={copyVol} onChange={(e) => { setCopyVol(e.target.value) }} />
                </div>
                <Button type="small"
                  action={copyAction_}>Kopiuj</Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CopyAction;