import React, { useEffect } from 'react';

function SingleToast({type, message, remove}) {

  useEffect(()=>{
    setTimeout(()=>{
      remove();
    }, 2000);
    }, [])

  return (
    <div className={"single-toast "+type+"-toast"}>
      <span className="toast-text">{ message }</span>
    </div>
  )
}

export default SingleToast