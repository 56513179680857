import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import PersonnelItem from "./PersonnelItem";
import Modal from "../modals/Modal";
import Calendar from '../calendar/Calendar';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import pl from 'date-fns/locale/pl';
import moment from 'moment';
import { setDate } from '../../store/actions/personnel';
import FilterModal from "../modals/FilterModal";
import FilterPersonnel from "../modals/FilterPersonnel";
import {createFilter} from '../SearchInput/SearchInput'
import { getPersonnel } from '../../store/reducers/personnel';
import { getUser } from '../../store/reducers/user';
registerLocale('pl', pl)

function PersonnelPage() {
  const dispatch = useDispatch();
  const personnel = useSelector(getPersonnel)
  const user = useSelector(getUser)
  const [popup, setPopup] = useState(false)
  const [filterPopup, setFilterPopup] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(null)

  useEffect(()=>{
    dispatch(setDate(moment().format("Y-M-D")));
  }, [])

  let filteredTerritories = personnel.list || [];

  if ( personnel.filter.territoryFilter) {
    filteredTerritories = personnel.list.filter(createFilter(personnel.filter.territoryFilter, ['territories.ident']))
  }

  const filteredPersonnel = filteredTerritories;

  return (
    <section className="personnel">
      <div className="page-header actions-header">
        <h1>Personel</h1>
        { user.role.uuid === "ASM" ?
        <a className="btn export-btn" onClick={ ()=>setFilterPopup(true) } target="_blank">Filtruj</a> : null }
        <div className="calendar-dropdown">
          <DatePicker
            selected={moment(personnel.date).toDate()}
            locale="pl"
            onChange={ (date) => {
              dispatch(setDate(moment(date).format("Y-M-D")));
            } }
            customInput={
              <p className="selected">{ moment(personnel.date).format("DD MMMM YYYY") }</p>
            }
          />
        </div>
      </div>
      <table className="personnel-table">
        <thead>
          <td>Imię i Nazwisko</td>
          <td>Dostępność</td>
          <td></td>
        </thead>
        <tbody>
          { filteredPersonnel.map((item, key) => {
            if (item.role.uuid === "HST") {
              return (
                <PersonnelItem person={ item } date={ personnel.date } openPopup={ ()=>{ setPopup(true); setSelectedPerson(item) } } />
              )
            }
            })
          }
        </tbody>
      </table>
      <Modal 
        open={ popup } 
        hide={ ()=>setPopup(false) } >
        <Calendar person={ selectedPerson }/>
      </Modal>
      <FilterModal 
        open={ filterPopup } 
        hide={ ()=>setFilterPopup(false) } >
          <FilterPersonnel hide={ ()=>setFilterPopup(false) } />
      </FilterModal>
    </section>
  );
}

export default PersonnelPage;