import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg';
import { remind } from '../store/actions/user';

function RemindPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else {
      dispatch(remind({username: email}, navigate))
    }
  }

  return (
    <section className="remind-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
        <h2>Odzyskaj hasło</h2>
        <p>Podaj e-mail</p>
        <form>
          <div className="center">
            <input type="email" placeholder="email" name="email" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="center">
            <button className="btn" onClick={ (e) => { validate(e) } }>Dalej</button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default RemindPage;