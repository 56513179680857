import _ from 'lodash'
import {
  ADD_ACTION,
  EDIT_ACTION,
  COPY_ACTION,
  UPLOAD_ACTIONS,
  UPLOAD_ACTIONS_REQUEST,
  SET_MONTH,
  COPY_START,
  COPY_UPDATE,
  COPY_END,
  SET_FILTER,
  LOGIN,
  SET_DATE_RANGE,
  QUEUE_EXPORT_ADD_REQUEST,
  QUEUE_EXPORT_ADD,
  QUEUE_FINISHED,
  QUEUE_DATA,
  WEBFORM_ACTION,
  WEBFORM_ACTION_REQUEST
} from "../actions/types";

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_ACTION:
      return {
        ...state,
        list: [action.action, ...state.list]
      }
    case EDIT_ACTION:
      const item = _.find(state.list, ['id', action.action.id]);
      const index = state.list.indexOf(item)
      return {
        ...state,
        list: [...state.list.slice(0, index), action.action, ...state.list.slice(index + 1)]
      }
    case COPY_ACTION:
      return {
        ...state,
        list: [action.action, ...state.list]
      }
    case UPLOAD_ACTIONS:
      /*const sorted = _.sortBy(action.payload.actions, [(item)=> { 
        if ( item.since != null ) {
          return item.since.date;
        } else {
          return false;
        }
      }]);*/
      return {
        ...state,
        list: action.payload.actions,
        count: action.payload.count,
        maxPage: Math.ceil(action.payload.count / 100),
        preloader: false
      }
    case WEBFORM_ACTION:
      return {
        ...state,
        casAction: {
          list: action.payload.actions,
          count: action.payload.count,
          maxPage: 0,
          preloader: false
        }
      }
    case UPLOAD_ACTIONS_REQUEST:
      return {
        ...state,
        page: action.payload,
        list: [],
        preloader: true
      }
    case SET_MONTH:
      return {
        ...state,
        month: action.payload
      }
    case COPY_START:
      return {
        ...state,
        copyCurrent: action.payload,
        copyVolume: action.payload
      }
    case COPY_UPDATE:
      return {
        ...state,
        copyCurrent: action.payload
      }
    case COPY_END:
      return {
        ...state,
        copyCurrent: 0,
        copyVolume: 0
      }
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload
      }
    case LOGIN:
      return {
        ...state,
        list: []
      }
    case SET_DATE_RANGE:
      return {
        ...state,
        since: action.payload.since,
        until: action.payload.until
      }
    case QUEUE_EXPORT_ADD_REQUEST:
      return {
        ...state,
        queueInProgress: true
      }
    case QUEUE_DATA:
      return {
        ...state,
        queueInProgress: true,
        queueData: action.payload
      }
    case QUEUE_FINISHED:
      return {
        ...state,
        queueInProgress: false,
        queueData: action.payload
      }
    default:
      return state;
  }
}

export const getActions = (state) => state.actions;
export const getCasAction = (state) => state.actions.casAction;