import {
  SET_FILTER_REPORTS,
  UPLOAD_REPORTS
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case UPLOAD_REPORTS:
      return {
        ...state,
        list: action.payload.actions,
        count: action.payload.count,
        maxPage: Math.ceil(action.payload.count/100)
      }
      case SET_FILTER_REPORTS:
        return {
          ...state,
          filter: action.payload
        }
    default:
      return state;
  }
}

export const getReports = (state) => state.reports;