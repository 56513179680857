import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { saveState } from '../localStorage';
import actionsReducer from "./reducers/actions";
import reportsReducer from "./reducers/reports";
import personnelReducer from "./reducers/personnel";
import toastsReducer from "./reducers/toasts";
import screenReducer from "./reducers/screen";
import userReducer from "./reducers/user";
import eventsReducer from "./reducers/events";
import locationsReducer from "./reducers/locations";
import territoriesReducer from "./reducers/territories";

const loggerMiddleware = store => next => action => {
  console.log("Action type: "+ action.type);
  console.log("Action payload:", action.payload);
  console.log("State before:", store.getState());
  next(action);
  console.log("State after:", store.getState());
};

export const store = createStore(
  combineReducers({
    actions: actionsReducer,
  reports: reportsReducer,
  personnel: personnelReducer,
  toasts: toastsReducer,
  screen: screenReducer,
  user: userReducer,
  events: eventsReducer,
  locations: locationsReducer,
  territories: territoriesReducer
  }),
  { 
    actions: {
      list: [],
      default: {
        id: "-",
        name: "",
        event: { name: ""},
        locations: [],
        date: "",
        timestart: 0,
        timeend: 0,
        personnel: [],
        queueData: []
      },
      filter: {},
      page: 0,
      preloader: false
    },
    reports: {
      list: []
    },
    personnel: {
      filter: {}
    },
    territories: []
  },
  compose(
    applyMiddleware(
      loggerMiddleware,
      thunk
    ),
  ),
)