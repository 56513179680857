import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";
import _ from 'lodash';
import Header from "../components/Header"
import { saveDimensionData } from '../store/actions/screen';
import Toasts from '../components/toasts/Toasts';
import LoginPage from '../components/LoginPage';
import ActionsPage from '../components/actions/ActionsPage';
import ReportsPage from '../components/reports/ReportsPage';
import PersonnelPage from '../components/personnel/PersonnelPage';
import RemindPage from '../components/RemindPage';
import RemindCodePage from '../components/RemindCodePage';
import EventsPage from '../components/events/EventsPage';
import CodesPage from '../components/codes/CodesPage';
import WebformNew from '../components/Webform/WebformNew'
import UserDataPage from '../components/UserDataPage';
import { ReactComponent as IconMenu } from '../../img/menu.svg'
import Navigation from '../components/Navigation';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'
import { checkSession } from '../store/actions/user';
import moment from 'moment';

function App() {
  const dispatch = useDispatch();
  const [navOpen, setNavOpen] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true)


  useEffect(()=>{
    const tkn = token && JSON.parse(token);
    if (tkn && moment(tkn.expires.date).isAfter(moment())) {
      dispatch({ type: 'SET_TOKEN', payload: { token: tkn } });
      dispatch(checkSession(navigate, tkn))
    } else {
      setLoading(false)
    }
    updateDimensions()
    window.addEventListener('resize', ()=>updateDimensions());
  },[])

  const updateDimensions = () => {
    dispatch(saveDimensionData({
      height: window.innerHeight,
      width: window.innerWidth
    }))
  }

  return (
    <>
    <div className="app">
      <Header />
      <Logo className="bat-logo"/>
      { location.pathname != '/' ? <div className="nav-trigger" onClick={ ()=>{ setNavOpen(true) }}><IconMenu /></div> : null }
      <Navigation open={navOpen} close={ ()=>{ setNavOpen(false) }} />

      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={ <LoginPage/> } />
        <Route path={`${process.env.PUBLIC_URL}/actions` } element={<ActionsPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/reports`} element={<ReportsPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/personnel`} element={ <PersonnelPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/remind`} element={<RemindPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/remind/code`} element={<RemindCodePage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/events`} element={<EventsPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/locations`} element={<EventsPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/regions`} element={<EventsPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/kody`} element={<CodesPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/twoje-konto`} element={<UserDataPage/>}/>
        <Route path={`${process.env.PUBLIC_URL}/webform`} element={<WebformNew/>}/>
      </Routes>
      
      <Toasts/>
      { loading && <div className="page-preloader"><span></span></div> }
    </div>
  </>
  )
}

export default App;