import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SingleToast from './SingleToast';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { removeToast } from '../../store/actions/toast'
import { getToasts } from '../../store/reducers/toasts';

function Toasts() {
  const dispatch = useDispatch()
  const toasts = useSelector(getToasts);

  return (
    <div className="toasts">
      <TransitionGroup>
        { toasts.map((toast, key) => {
          return (
            <CSSTransition
              key={key}
              classNames="toast"
              timeout={{ enter: 500, exit: 300 }}
            >
              <SingleToast { ...toast } remove={ () => { dispatch(removeToast(toast.id)) } } />
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </div>
  )
}

export default Toasts;