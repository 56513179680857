import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { queueExport, listQueue, downloadReport } from '../../store/actions/actions';
import Button from "../Button";
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import { getActions } from '../../store/reducers/actions';

function Export({hide, finished}) {
  const dispatch = useDispatch();
  const actions = useSelector(getActions);
  const [since, setSince] = useState(moment());
  const [until, setUntil] = useState(moment().add(7, 'days'));

  useEffect(()=>{
    dispatch(listQueue())
  }, [])

  const sinceDate = since.toDate();
  const untilDate = until.toDate();
  const CalendarInput = ({ value, onClick }) => (
    <CalendarIcon className="icon-calendar"  onClick={onClick}/>
  );

  let queued = actions.queueData?.queued ? actions.queueData.queued : []
  if (queued.length > 0) {
    queued = queued.filter((item, key)=>{
      return item.isMine
    })
  }
  if (finished) {
    queued = queued.filter((item, key)=>{
      const isQueued = item.parameters.actions?.statuses?.idents.indexOf('finished')
      return isQueued >= 0 && isQueued != undefined ? true : false
    })
  }

  let available = actions.queueData?.finished ? actions.queueData.finished : []

  if (finished) { 
    available = available.filter((item, key)=>{
      const isFinished = item.parameters.actions?.statuses?.idents.indexOf('finished')
      return isFinished >= 0 && isFinished != undefined ? true : false
    })
  }

  return (
    <div className="export-wrap">
      <h3>Zakres raportu</h3>
      <div className="column-row">
        <div className="date-row">
          <div className="since-date">
            <span>od </span>
            <label>{ since.format('YYYY.MM.DD') }</label>
            <DatePicker
              selected={sinceDate}
              locale="pl"
              popperPlacement="bottom-right"
              onChange={ (sinceDate) => { setSince(moment(sinceDate)) }}
              customInput={<CalendarInput/>}
            />
          </div>
          <div className="since-date">
            <span>do </span>
            <label>{ until.format('YYYY.MM.DD') }</label>
            <DatePicker
              selected={untilDate}
              locale="pl"
              popperPlacement="bottom-right"
              onChange={ (untilDate) => { setUntil(moment(untilDate)) }}
              customInput={<CalendarInput/>}
            />
          </div>
        </div>
        <div className="select-row">
          {/*<label htmlFor="filetype">Rodzaj pliku</label>
          <select id="filetype">
            <option value="xlsx">XLSX</option>
            </select>*/}
        </div>
      </div>
      
      <h3>Przetwarzane raporty</h3>
      <ul>
        { queued.map((item, key) => {
          return <li><div>{ `${item.parameters?.since} - ${item.parameters?.until}` }</div></li>
        })}
      </ul>
                    
      <div className="buttons">
        <Button type={ actions.queueInProgress ? 'inactive' : '' } action={ ()=>{
          if (!actions.queueInProgress) {
            dispatch(queueExport(
              'xlsx', 
              since.format('YYYY-MM-DD'), 
              until.format('YYYY-MM-DD'), 
              finished ? true : false
            )) }
          }
        }>Generuj</Button>
      </div>
      <h3>Raporty do pobrania</h3>
      <ul>
        { available?.map((item, key) => {
          return (
            <li>
              <div>{ `${item.parameters.since} - ${item.parameters.until}` } <br/><span className="small">Utworzony: { moment(item.updated.date).format('YYYY.MM.DD HH:MM:ss')}</span></div>
              <div><Button type="empty" action={ ()=>{
                dispatch(downloadReport(item.uuid, item.parameters))
              }}>Pobierz</Button></div>
            </li>
          )
        }) }
      </ul>
    </div>
  );
}

export default Export;