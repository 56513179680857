import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import EventItem from "./EventItem";
import ActionModal from "../modals/ActionModal";
import AddEditEvent from "./AddEditEvent";
import moment from 'moment';
import { fetchEvents } from '../../store/actions/events';
import _ from 'lodash';
import { getEvents } from '../../store/reducers/events';

function EventsPage() {
  const dispatch = useDispatch();
  const events = useSelector(getEvents);
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [eventID, setEventID] = useState(-1);
  const [copy, setCopy] = useState(false);
  const [since, setSince] = useState(moment());
  const [until, setUntil] = useState(moment().add(7, 'days'));

  useEffect(()=>{
    dispatch(fetchEvents('',{since: moment().format('YYYY-MM-DD'), until: moment().add(7, 'days').format('YYYY-MM-DD')}))
  }, [])

  const openActionPopup = (id, copy) => {
    setAddEditPopup(true);
    setEventID(id);
    setCopy(copy)
  }

  return (
    <section className="actions events">
      <div className="page-header actions-header">
        <h1>Eventy</h1>
        <Button type="add-action" action={ ()=>{ openActionPopup(-1) } }>Dodaj event<span>+</span></Button>
      </div>
      <table className={ addEditPopup ? "actions-table popup-active" : "actions-table" }>
        <thead>
          <tr>
            <td>Nr</td>
            <td>Nazwa</td>
            <td>Id</td>
            <td>Data rozpoczęcia</td>
            <td>Data zakończenia</td>
            <td>Terytorium</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          { events.list.map((item, key) => {
              return (
                <EventItem key={'action-'+key} nr={key+1} event={ item } openPopup={ ()=>setAddEditPopup(true) } range={{since: since.format('YYYY-MM-DD'), until: until.format('YYYY-MM-DD')}} />
              )
            })
          }
        </tbody>
      </table>
      <ActionModal 
        open={ addEditPopup } 
        hide={ ()=>setAddEditPopup(false) } >
        <AddEditEvent ident={ eventID } hide={ this.hideActionPopup } range={{since: since.format('YYYY-MM-DD'), until: until.format('YYYY-MM-DD')}} />
      </ActionModal>
    </section>
  );
  
}

export default EventsPage;