import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom'
import { store } from './app/store/store';
import App from './app/containers/App'
import NiceModal from '@ebay/nice-modal-react';
import './scss/style.scss';
import './app/components/Webform/modals'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NiceModal.Provider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </NiceModal.Provider>
);  