import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import Button from "../Button";
import CodeItem from "./CodeItem";
import moment from 'moment';
import { getEvents } from '../../store/actions/events';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import _ from 'lodash';

function CodesPage() {
  const dispatch = useDispatch();
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [eventID, setEventID] = useState(-1);
  const [copy, setCopy] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [filterPopup, setFilterPopup] = useState(false);
  const [since, setSince] = useState(moment());
  const [until, setUntil] = useState(moment().add(7, 'days'));
  const codes = [
    {
      name: "VYPE Horeca 2020",
      sum: 500,
      released: 150,
      assigned: 150,
      available: 200,
      groups: [
        {
          name: "Group 1",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 2",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 3",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 4",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 5",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        }
      ]
    },
    {
      name: "GLO Horeca",
      sum: 500,
      released: 150,
      assigned: 150,
      available: 200,
      groups: [
        {
          name: "Group 1",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 2",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 3",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 4",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        },
        {
          name: "Group 5",
          sum: 100,
          released: 30,
          assigned: 30,
          available: 40
        }
      ]
    }
  ]

  const CalendarInput = ({ value, onClick }) => (
    <CalendarIcon className="icon-calendar"  onClick={onClick}/>
  );

  return (
    <section className="codes">
      <div className="page-header actions-header">
        <h1>Kody</h1>
        <Button type="export" >Importuj CSV</Button>
        <Button type="export" >Eksportuj CSV</Button>
      </div>
      <table className={ addEditPopup ? "codes-table popup-active" : "codes-table" }>
        <thead>
          <tr>
            <td>Nr</td>
            <td>Event</td>
            <td>Suma</td>
            <td>Wydane</td>
            <td>Przypisane</td>
            <td>Dostępne</td>
          </tr>
        </thead>
        <tbody>
          { codes.map((item, key) => {
              return (
                <CodeItem key={'code-'+key} nr={key+1} data={ item } />
              )
            })
          }
        </tbody>
      </table>
    </section>
  );
}

export default CodesPage;