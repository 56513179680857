import React, { useState } from 'react';
import Button from "../Button";
import _ from 'lodash';
import moment from 'moment';

function PersonnelItem({person, date, openPopup}) {
  const [active, setActive] = useState(false)

  let editable = false;
  let status = 'unavailable';
  let availableToday = {timestart: '', timeend: ''}
  _.each(person.availability, (av, index)=>{
    if (av.since && moment(av.since.date).format('y-m-d') === moment(date).format('y-m-d')) {
      status = 'available';
      availableToday = { timestart: moment(av.since.date).format('HH:mm'), timeend: moment(av.until.date).format('HH:mm')}
    }
  })

  return (
    <tr className={ "action-row "+status }>
      <td className="action-name"><span>{ person.firstname+" "+person.lastname }</span></td>
      <td>{ availableToday.timestart+' - '+availableToday.timeend }</td>
      <td>
        <div className="buttons">
          <Button action={ openPopup }>Kalendarz</Button>
        </div>
      </td>
    </tr>
  );
}

export default PersonnelItem;