import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import moment from 'moment';
import { getUser } from '../../store/reducers/user';

function ReportItem({ nr, action, setPhotoModal }) {
  const dipatch = useDispatch();
  const user = useSelector(getUser)
  const [photos, setPhotos] = useState({
    currentAction: "",
    expanded: false,
    list: []
  })
  const [loading, setLoading] = useState(false)

  let editable = false;
  if (action.status.global.ident != 'cancelled' && action.status != 'active' && action.status != 'finished') {
    editable = true
  }
  const icons = {
    'active': <ActiveIcon />,
    'editable': <NewIcon />,
    'cancelled': <CanceledIcon />,
    'finished': <EndedIcon />,
    'accepted': <ApprovedIcon />
  }

  const territory = user.role.uuid === "ASM" || user.role.uuid === "HSADM" ? <td>{action.territory != null ? action.territory.ident : ''}</td> : null

  const getReceipts = async () => {
    if (photos.list.length === 0) {
      setLoading(true)
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/action/receipt/list",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem('token'))?.uuid
        },
        body: JSON.stringify({
          "action": {
            "uuid": action.uuid
          },
          "pagination": {
            "limit": 100,
            "offset": 0
          }
        })
      }
    );
    const receipts = await response.json();
    const imgs = []
    for (const receipt of receipts.data.receipts) {
      imgs.push({
        img: await getPhoto(receipt.uuid),
        date: new Date(receipt.created.date).toLocaleTimeString('pl-PL', {
          hour: '2-digit',
          minute: '2-digit'
        })
      })
    }
    if(imgs.length === 0) {
      setPhotos(prev => ({ ...prev, list: "none" }))
    } else {
      setPhotos(prev => ({ ...prev, list: [...imgs] }))
    }
    setLoading(false)
  };

  console.log(photos)

  const getPhoto = async (uuid) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/action/receipt/serve",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem('token'))?.uuid
        },
        body: JSON.stringify({
          "receipt": {
            "uuid": uuid
          },
        })
      }
    );
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  };

  return (
    <>
      <tr className={`action-row ${action.status.global.ident} ${photos.expanded ? "no-border-bottom" : ""}`}>
        <td className="status-cell" tabIndex="0" onBlur={() => { this.setState({ statusSelect: false }) }}>
          <div className={"status-icon"}>{icons[action.status.global.ident]}</div>
        </td>
        <td className="action-nr"><span>{nr}</span></td>
        <td className="action-name"><span>{action.name}</span></td>
        <td>{action.ident}</td>
        {territory}
        <td>{action.event != null ? action.event.name : ''}</td>
        <td>
          {action.actionPoints.map((item, key) => {
            return <p key={key}>{item.name + ', ' + item.address.streetAddress + ' ' + (item.address.streetNumber != null ? item.address.streetNumber : '') + ' ' + item.address.cityName}</p>
          })}
        </td>
        <td>{action.since != null ? moment(action.since.date).format('D/M/Y') : ''}</td>
        <td>{action.since != null && action.until != null ? moment(action.since.date).format('H:mm') + ' - ' + moment(action.until.date).format('H:mm') : ''}</td>
        <td>
          {action.users ? action.users.map((item, key) => {
            return <p key={"item-" + key}>{item.firstname + ' ' + item.lastname}</p>
          }) : null}
        </td>
        <td>
          <div className="buttons">
            <button onClick={() => { getReceipts(); setPhotos(prev => ({ ...prev, expanded: !prev.expanded })) }} className="btn">
              {loading ? "Ładowanie" : photos.expanded ? "Ukryj paragony" : "Pokaż paragony"}
            </button>
          </div>
        </td>
      </tr>
      {photos.expanded && <tr className='report-photos'>
        <td colSpan={10}>
          {<div className="photos-wrapper" >

            {photos.list === "none" ?
              <p>Brak zdjęć</p>
              :
              photos.list.map((element, index) => (
                <div className='photo' key={index} >
                  <img src={element.img} alt="" onClick={() => setPhotoModal({open: true, imgUrl: element.img})}/>
                  <p>Paragon #{index + 1} - {element.date}</p>
                </div>
              ))}
          </div>}
        </td>
      </tr>}
    </>
  );
}

export default ReportItem;