import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from '../../store/actions/actions';
import Button from "../Button";
import _ from 'lodash';
import SearchInput, {createFilter} from '../SearchInput/SearchInput'
import SearchField from "../SearchField"
import "react-datepicker/dist/react-datepicker.css";
import { fetchLocations, resetLocations} from '../../store/actions/locations';
import { fetchTerritories } from '../../store/actions/territories';
import { setDate, fetchPersonnel } from '../../store/actions/personnel';
import { getActions } from '../../store/reducers/actions';
import { getUser } from '../../store/reducers/user';
import { getPersonnel } from '../../store/reducers/personnel';

function Filter({hide}) {
  const dispatch = useDispatch()
  const actions = useSelector(getActions)
  const user = useSelector(getUser)
  const personnel = useSelector(getPersonnel)
  const [cityFilter, setCityFilter] = useState(actions?.filter?.cityFilter || '')
  const [cityFocus, setCityFocus] = useState(false);
  const [streetFilter, setStreetFilter] = useState(actions?.filter?.streetFilter || '')
  const [streetFocus, setstreetFocus] = useState(false);
  const [idFilter, setIdFilter] = useState(actions?.filter?.idFilter || '')
  const [idFocus, setIdFocus] = useState(false);
  const [territoryFilter, setTerritoryFilter] = useState(actions?.filter?.territoryFilter || '')
  const [territoryFocus, setTerritoryFocus] = useState(false);
  const [territoryUUID, setTerritoryUUID] = useState('')
  const [personnelFilter, setPersonnelFilter] = useState(actions?.filter?.personnelFilter || '')
  const [personnelFocus, setPersonnelFocus] = useState(false);
  const [personnelUUID, setPersonnelUUID] = useState('')
  const [eventFilter, setEventFilter] = useState(actions?.filter?.eventFilter || '')
  const [eventFocus, setEventFocus] = useState(false);
  const [eventUUID, setEventUUID] = useState('')
  const [statusFilter, setStatusFilter] = useState(actions?.filter?.statusFilter || '')
  const [statusFocus, setStatusFocus] = useState(false);

 const saveFilter = () => {
    dispatch(setFilter({
      cityFilter: cityFilter, 
      streetFilter: streetFilter,
      idFilter: idFilter,
      territoryFilter: territoryFilter,
      territoryFilterUUID: territoryUUID,
      personnelFilter: personnelFilter,
      personnelFilterUUID: personnelUUID,
      eventFilter: eventFilter,
      eventFilterUUID: eventUUID,
      statusFilter: statusFilter
    }));
    hide()
  }

  const clearFilter = () => {
    setCityFilter('');
    setStreetFilter('');
    setIdFilter('');
    setTerritoryFilter('');
    setPersonnelFilter('');
    setEventFilter('');
    setStatusFilter('');

    dispatch(setFilter({
      cityFilter: '', 
      streetFilter: '',
      idFilter: '',
      territoryFilter: '',
      personnelFilter: '',
      eventFilter: '',
      statusFilter: ''
    }));
  }

  const filteredCities = actions.list.filter(createFilter(cityFilter, ['actionPoints.address.cityName']))
  const filteredStreets = actions.list.filter(createFilter(streetFilter, ['actionPoints.address.streetAddress']))
  const filteredIds = actions.list.filter(createFilter(idFilter, ['ident']))
  const filteredTerritories = user?.territories?.filter(createFilter(territoryFilter, ['ident']))
  const filteredPersonnel = personnel.list.filter(createFilter(personnelFilter, ['firstname', 'lastname']))
  const filteredEvents = actions.list.filter(createFilter(eventFilter, ['event.name']))
  const filteredStatuses = actions.list.filter(createFilter(eventFilter, ['status.global.ident']))

  return (
    <div className="filter-wrap">
      <div className="filters">
        <div className="location-filters">
          <h3>Lokalizacja</h3>
          <SearchField value={cityFilter} placeholder="Miasto" onChangeAction={(filter) => { setCityFilter(filter) }}>
            <ul className={ cityFocus != '' ? 'active' : '' }>
              { _.uniqBy(filteredCities, 'actionPoints[0].address.cityName').map((item, i) => {
                if (item.actionPoints.length > 0) {
                  return <li key={ i } onMouseDown={()=>{setCityFilter(item.actionPoints[0].address.cityName)}} >{ item.actionPoints[0].address.cityName }</li>
                }
              }) }
            </ul>
          </SearchField>

          <SearchField value={streetFilter} placeholder="Ulica" onChangeAction={(filter) => { setStreetFilter(filter) }}>
          <ul className={ streetFocus != '' ? 'active' : '' }>
              { _.uniqBy(filteredStreets, 'actionPoints[0].address.streetAddress').map((item, i) => {
                if (item.actionPoints.length > 0) {
                  return <li key={ i } onMouseDown={()=>{setStreetFilter(item.actionPoints[0].address.streetAddress)}} >{ item.actionPoints[0].address.streetAddress }</li>
                }
              }) }
            </ul>
          </SearchField>

          <SearchField value={idFilter} placeholder="ID" onChangeAction={(filter) => { setIdFilter(filter) }}>
            <ul className="search-list">
              { _.uniqBy(filteredIds, 'ident').map((item, i) => {
                return <li key={ i } onMouseDown={()=>{setIdFilter(item.ident)}} >{ item.ident }</li>
              }) }
            </ul>
          </SearchField>

          { user.role.uuid === "ASM" || user.role.uuid === "HSADM" ?
            <SearchField value={territoryFilter} placeholder="Terytorium" onChangeAction={(filter) => { setTerritoryFilter(filter) }}>
              <ul className={ territoryFocus != '' ? 'active' : '' }>
                { _.uniqBy(filteredTerritories, 'ident').map((item, i) => {
                  return <li key={ i } onMouseDown={()=>{
                    setTerritoryFilter(item.ident);
                    setTerritoryUUID(item.uuid);
                    }} >{ item.ident }</li>
                }) }
              </ul>
            </SearchField>
          : null }
        </div>
        <div className="utility-filters">
          <h3>Personel</h3>
          <SearchField value={personnelFilter} placeholder="Imię i Nazwisko" onChangeAction={ (filter) => { setPersonnelFilter(filter) }}>
            <ul className="search-list">
              { filteredPersonnel.map((item, i) => {
                return <li key={ i } onMouseDown={()=>{
                  setPersonnelFilter(item.firstname+' '+item.lastname); 
                  setPersonnelUUID(item.uuid);
                }} >{ item.firstname+' '+item.lastname }</li>
              }) }
            </ul>
          </SearchField>
          <h3>Status akcji</h3>
          <SearchField value={statusFilter} placeholder="Status" onChangeAction={(filter) => { setStatusFilter(filter) }}>
            <ul className={ statusFocus != '' ? 'active' : '' }>
              { _.uniqBy(filteredStatuses, 'status.global.ident').map((item, i) => {
                return <li key={ i } onMouseDown={()=>{
                  setStatusFilter(item.status.global.ident)}} >{ item.status.global.ident }</li>
              }) }
            </ul>
          </SearchField>
          <h3>Event</h3>
          <SearchField value={eventFilter} placeholder="Nazwa Eventu" onChangeAction={(filter) => { setEventFilter(filter) }}>
            <ul className={ eventFocus != '' ? 'active' : '' }>
              { _.uniqBy(filteredEvents, 'event.name').map((item, i) => {
                return <li key={ i } onMouseDown={()=>{
                  setEventFilter(item.event.name); 
                  setEventUUID(item.event.uuid);
                }} >{ item.event.name }</li>
              }) }
            </ul>
          </SearchField>
        </div>
      </div>
      <div className="buttons">
        <Button type="orange" action={ ()=>clearFilter() }>Resetuj filtr</Button>
        <Button action={ ()=>saveFilter() }>Zapisz</Button>
      </div>
    </div>
  );
  
}

export default Filter;