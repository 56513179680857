import React, { useState } from 'react';
import Button from "../Button";
import moment from 'moment';

function EventItem({nr, event, openPopup, range}) {

  return (
    <tr className={ "action-row " }>
      <td className="action-nr"><span>{ nr }</span></td>
      <td className="action-name"><span>{ event.name }</span></td>
      <td>{ event.ident }</td>
      <td>{ event.since != null ? moment(event.since.date).format('D/M/Y') : '' }</td>
      <td>{ event.until != null ? moment(event.until.date).format('D/M/Y') : '' }</td>
      <td>
      { event.territory? event.territory.map((item, key) => {
          return <p key={ "item-"+key }>{ item.ident }</p>
        }) : null }
      </td>
      <td>
        <div className="buttons">
          <Button event={ ()=>{openPopup(event.ident)} }>Edytuj</Button>
        </div>
      </td>
    </tr>
  );
}

export default EventItem;