import React, { useState } from 'react';

function CodeItem({data}) {
  const [showGroups, setShowGroups] = useState(false);

  return (
    <>
      <tr className="action-row codes-row">
        <td className="action-nr" rowspan={showGroups ? data.groups.length+1 : 1}><span>{ this.props.nr }</span></td>
        <td className="codes-name" style={{ width: "30%"}} onClick={ ()=>{ setShowGroups(!showGroups) }}>
          <span>{ data.name }</span>
        </td>
        <td>{ data.sum }</td>
        <td>{ data.released }</td>
        <td>{ data.assigned }</td>
        <td>{ data.available }</td>
      </tr>
      { data.groups.map(( group, key)=>{
          return (
            <tr className={ showGroups ? "action-row group-row active" : "action-row group-row"}>
              <td>
                <span>{ group.name }</span>
              </td>
              <td>{ group.sum }</td>
              <td>{ group.released }</td>
              <td>{ group.assigned }</td>
              <td>{ group.available }</td>
            </tr>
          )
        })
      }
    </>
  );
}

export default CodeItem;