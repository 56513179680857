import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import ActionItem from "./ActionItem";
import ActionModal from "../modals/ActionModal";
import FilterModal from "../modals/FilterModal";
import AddEditAction from "./AddEditAction";
import Filter from "../modals/Filter";
import CopyAction from "./CopyAction";
import moment from 'moment';
import { uploadActions, generateFilteredReport, setDateRange } from '../../store/actions/actions';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import _ from 'lodash';
import Dropdown from "../Dropdown";
import ReactPaginate from 'react-paginate';
import Export from '../modals/Export';
import ExportModal from '../modals/ExportModal';
import { getActions } from '../../store/reducers/actions';
import { getUser } from '../../store/reducers/user';
import { getPersonnel } from '../../store/reducers/personnel';
import HSTCanceledInfo from '../modals/HSTCanceledInfo';

function ActionsPage() {
  const dispatch = useDispatch();
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [actionID, setActionID] = useState(-1);
  const [copy, setCopy] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [filterPopup, setFilterPopup] = useState(false);
  const [exportPopup, setExportPopup] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [infoPopupShown, setInfoPopupShown] = useState(false)
  const [since, setSince] = useState(moment());
  const [until, setUntil] = useState(moment().add(7, 'days'));
  const [page, setPage] = useState(false);
  const actions = useSelector(getActions);
  const user = useSelector(getUser);
  const personnel = useSelector(getPersonnel);

  useEffect(()=>{
    setTimeout(()=>{
      dispatch(setDateRange(
        moment().format('YYYY-MM-DD'), 
        moment().add(7, 'days').format('YYYY-MM-DD')
      ))}, 500)
  }, [])

  useEffect(()=>{
    if (!infoPopupShown && actions.list.length > 0) {
      const hstCancelled = actions.list.filter((action) => {
        return action.status.global.ident === 'hst_cancelled';
      })
      if (hstCancelled.length > 0) {
        setInfoPopup(true);
        setInfoPopupShown(true);
      }
    }
  }, [actions.list])

  const openActionPopup = (id, copy) => {
    setActionID(id);
    setCopy(copy);
    setAddEditPopup(true);
  }

  const handleUploadActions = (s, u, id) => {
    dispatch(uploadActions(
      '',
      {
        since: s.format('YYYY-MM-DD'), 
        until: u.format('YYYY-MM-DD')
      }, 
      id
    ))
  }

  const sinceDate = since.toDate();
  const untilDate = until.toDate();

  const CalendarInput = ({ value, onClick }) => (
    <CalendarIcon className="icon-calendar"  onClick={onClick}/>
  );

  const filteredActions = actions?.list;
  const filteredActionsIDs = filteredActions?.map((item, key)=>{
    return item.uuid;
  })

  return (
    <section className="actions">
      <div className="page-header actions-header">
        <h1>Akcje</h1>
        <a className="btn export-btn" onClick={ ()=>{setExportPopup(true)} }>Eksportuj</a>
        <a className="btn export-btn" onClick={ ()=>{setFilterPopup(true)} } target="_blank">Filtruj</a>
        <Dropdown classNames="calendar-dropdown" selected={ since.format('YYYY.MM.DD')+' - '+until.format('YYYY.MM.DD') }>
          <>
            <div className="since-date">
              <span>od </span>
              <label>{ since.format('YYYY.MM.DD') }</label>
              <DatePicker
                selected={sinceDate}
                locale="pl"
                popperPlacement="bottom-right"
                onChange={ (sinceDate) => {
                  let dt = moment(sinceDate);
                  setSince(dt);
                  handleUploadActions(dt,until,0);
                } }
                customInput={<CalendarInput/>}
              />
            </div>
            <div className="since-date">
              <span>do </span>
              <label>{ until.format('YYYY.MM.DD') }</label>
              <DatePicker
                selected={untilDate}
                locale="pl"
                popperPlacement="bottom-right"
                onChange={ (untilDate) => {
                  let dt = moment(untilDate);
                  setUntil(dt);
                  handleUploadActions(since,dt,0);
                } }
                customInput={<CalendarInput/>}
              />
            </div>
          </>
        </Dropdown>
        <Button type="add-action" action={ ()=>{ openActionPopup(-1) } }>Dodaj akcję<span>+</span></Button>
      </div>
      <table className={ addEditPopup ? "actions-table popup-active" : "actions-table" }>
        <thead>
          <tr>
            <td>Status</td>
            <td>Nr</td>
            <td>Nazwa</td>
            <td>Id</td>
            { user && (user.role.uuid === "ASM" || user.role.uuid === "HSADM" ? <td>Terytorium</td> : null) }
            <td>Event</td>
            <td>Lokalizacja</td>
            <td>Data</td>
            <td>Godzina</td>
            <td>Personel</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          { filteredActions.map((item, key) => {
              return (
                <ActionItem key={'action-'+key} nr={key+1} action={ item } openPopup={ openActionPopup } range={{since: since.format('YYYY-MM-DD'), until: until.format('YYYY-MM-DD')}} />
              )
            })
          }
        </tbody>
      </table>
      <ActionModal 
        open={ addEditPopup } 
        hide={ ()=>{setAddEditPopup(false)} } >
        { copy ? <CopyAction ident={ actionID } hide={ ()=>{setAddEditPopup(false)} } range={{since: since.format('YYYY-MM-DD'), until: until.format('YYYY-MM-DD')}} /> :
          <AddEditAction ident={ actionID } hide={ ()=>{setAddEditPopup(false)} } range={{since: since.format('YYYY-MM-DD'), until: until.format('YYYY-MM-DD')}} />
        }
      </ActionModal>
      <FilterModal 
        open={ filterPopup } 
        hide={ ()=>{setFilterPopup(false)} } >
          <Filter hide={ ()=>{setFilterPopup(false)} } />
      </FilterModal>
      <ExportModal 
        open={ exportPopup } 
        hide={ ()=>{setExportPopup(false)} } >
          <Export hide={ ()=>setExportPopup(false) } finished={ false } />
      </ExportModal>
      <ExportModal 
        open={ infoPopup } 
        hide={ ()=>{setInfoPopup(false)} } >
          <HSTCanceledInfo hide={ ()=>{setInfoPopup(false)} } />
      </ExportModal>
      { actions.count > 100 ?
        <ReactPaginate
          previousLabel={'Poprzednia'}
          nextLabel={'Następna'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={actions.maxPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => { handleUploadActions(since, until, data.selected) }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        /> : null }
    </section>
  );

}

export default ActionsPage;