import {
  GET_EVENTS
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        list: action.payload.reverse(),
      }

    default:
      return state;
  }
}

export const getEvents = (state) => state.events;