import {
  LOGIN,
  LOGOUT,
  REMIND,
  SESSION,
  SET_TOKEN
} from "../actions/types";

function hidePreloader() {
  const preloader = document.querySelector("div.page-preloader")
  setTimeout(() => {
    preloader.classList.add("hide")
  }, 600)
}

export default function(state = {}, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token
      }
    case SESSION:
        hidePreloader()
        return {
          ...state,
          token: action.payload.token,
          user: action.payload.user
        }
    case LOGOUT:
        return {
          ...state,
          token: null,
          user: null
        }
    case REMIND:
        return {
          ...state,
          remindEmail: action.payload,
        }

    default:
      return state;
  }
}

export const getUser = (state) => state.user.user
export const getToken = (state) => state.user.token;
export const getRemindEmail = (state) => state.user.remindEmail;
export const getUserID = (state) => state.user?.user?.ident;