import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { statusChange } from '../../store/actions/actions';
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import { ReactComponent as HSTCanceledIcon } from '../../../img/icon-hst-canceled.svg';
import moment from 'moment';
import { addToast } from '../../store/actions/toast';
import { getUser } from '../../store/reducers/user';

function ActionItem({ nr, action, openPopup, range }) {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [statusSelect, setStatusSelect] = useState(false);

  const changeStatus = (action, newStatus) => {
    if (checkFields(action)) {
      dispatch(statusChange({
        status: {
          ident: newStatus
        },
        action: {
          uuid: action.uuid
        }
      }, range))
    } else {
      dispatch(addToast({ type: "error", message: "Uzupełnij brakujące dane" }));
    }
  }

  const checkFields = (action) => {
    if (action.event != null && action.users != null && action.actionPoints != null && action.since != null && action.until != null) {
      return true;
    } else {
      return false;
    }
  }

  let editable = false;
  if (action.status.global.ident != 'cancelled' && action.status.global.ident != 'started' && action.status.global.ident != 'finished') {
    editable = true
  }
  const icons = {
    'started': <ActiveIcon />,
    'editable': <NewIcon />,
    'cancelled': <CanceledIcon />,
    'finished': <EndedIcon />,
    'accepted': <ApprovedIcon />,
    'hst_cancelled': <HSTCanceledIcon />,
  }

  const territory = user && (user.role.uuid === "ASM" || user.role.uuid === "ADMIN" || user.role.uuid === "HSADM") ? <td>{action.territory != null ? action.territory.ident : ''}</td> : null

  return (
    <tr className={"action-row " + action.status.global.ident}>
      <td className="status-cell" tabIndex="0" onBlur={() => { setStatusSelect(false) }}>
        <div className={"status-icon"} onClick={() => { setStatusSelect(true) }}>{icons[action.status.global.ident]}</div>
        <ul className={statusSelect ? "status-select open" : "status-select"}>
          <li onClick={() => {
            setStatusSelect(false)
            changeStatus(action, "editable")
          }}><NewIcon /></li>
          <li onClick={() => {
            setStatusSelect(false)
            changeStatus(action, "accepted")
          }}><ApprovedIcon /></li>
          <li onClick={() => {
            setStatusSelect(false)
            changeStatus(action, "cancelled")
          }}><CanceledIcon /></li>
        </ul>
      </td>
      <td className="action-nr"><span>{nr}</span></td>
      <td className="action-name"><span>{action.name}</span></td>
      <td>{action.ident}</td>
      {territory}
      <td>{action.event != null ? action.event.name : ''}</td>
      <td className='action-location-with-distance'>
        {[(({ actionPoints, startLat, startLng, startDistance, outOfBounds }) => ({ ...actionPoints[0], startLat, startLng, startDistance, outOfBounds }))(action)].map((item, key) => (
          <div className="flex-wrapper" key={key}>
            <p key={key}>{item?.name === undefined && item?.address?.cityName === undefined ? <span style={{color: "red"}}>Niepoprawny adres</span> : item.name + ', ' + item?.address?.streetAddress + ' ' + (item?.address?.streetNumber != null ? item?.address?.streetNumber : '') + ' ' + item?.address?.cityName}</p>
            {item.startDistance && <div className="location">!
              <div className="tooltip">
                <p>Dystans: <strong>{item.startDistance}m</strong></p>
              </div>
            </div>}
          </div>
        ))}
      </td>
      <td>{action.since != null ? moment(action.since.date).format('D/M/Y') : ''}</td>
      <td>{action.since != null && action.until != null ? moment(action.since.date).format('H:mm') + ' - ' + moment(action.until.date).format('H:mm') : ''}</td>
      <td>
        {action.users ? action.users.map((item, key) => {
          return <p key={"item-" + key}>{item.firstname + ' ' + item.lastname}</p>
        }) : null}
      </td>
      <td>
        <div className="buttons">
          <Button action={() => { openPopup(action.ident, true) }}>Powiel</Button>
          {editable ? <Button action={() => { openPopup(action.ident) }}>Edytuj</Button> : null}
        </div>
      </td>
    </tr>
  );
}

export default ActionItem;