import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector} from "react-redux";
import { addEvent, editEvent } from '../../store/actions/events';
import Button from "../Button";
import _, { map } from 'lodash';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg'
import { ReactComponent as CanceledIcon } from '../../../img/icon-canceled.svg';
import { ReactComponent as NewIcon } from '../../../img/icon-new.svg';
import { ReactComponent as ApprovedIcon } from '../../../img/icon-approved.svg';
import { ReactComponent as EndedIcon } from '../../../img/icon-ended.svg';
import { ReactComponent as ActiveIcon } from '../../../img/icon-active.svg';
import SearchInput, {createFilter} from '../SearchInput/SearchInput'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import pl from 'date-fns/locale/pl';
import { setDate, fetchPersonnel } from '../../store/actions/personnel';
import { fetchTerritories } from '../../store/actions/territories';
import { getTerritories } from '../../store/reducers/territories';
registerLocale('pl', pl)

function AddEditEvent({ident,hide,range}) {
  const dispatch = useDispatch()
  const defaultEvent = {
    new: true, 
    ident: "event-"+moment().format("DD.MM.YYYY"), 
    name: "",
    chain: "",
    territory: []
  }
  const [event, setEvent] = useState(_.find(this.props.events.list, [ 'ident', ident ]) || defaultEvent)
  const [activeInput, setActiveInput] = useState("eventName") // eventName, eventIdent, eventChain
  const [selectedTerritories, setSelectedTerritories] = useState([]);
  const territories = useSelector(getTerritories);
  const [territoryFilter, setTerritoryFilter] = useState('');
  const [day, setDay] = useState(event.since ? moment(event.since.date).format('D') : '');
  const [month, setMonth] = useState(event.since ? moment(event.since.date).format('M') : '');
  const [year, setYear] = useState(event.since ? moment(event.since.date).format('Y') : '');
  const [timestart, setTimestart] = useState(event.since ? moment(event.since.date).format('HH:mm') : '');
  const [timeend, setTimeend] = useState(event.since ? moment(event.until.date).format('HH:mm') : '');

  useEffect(()=>{
    dispatch(fetchTerritories());
  }, [])

  const handleInputChange = (e) => {
    const target = e.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    setEvent({
      ...event, 
      name: value, 
      ident: 'event-'+value+'-'+moment().format("DD.MM.YYYY")
    })
  }

  const handleDateChange = (e) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    let day = day;
    let month = month;
    let year = year;
    if (name === 'day') day = value;
    if (name === 'month') month = value;
    if (name === 'year') year = value;
    if (moment(month+'.'+day+'.'+year).isValid()) {
      setDay(day);
      setMonth(month);
      setYear(year);
      setEvent({ 
        ...event, 
        since: { date: moment(month+'.'+day+'.'+year+' '+timestart).format("YYYY-MM-DD HH:mm:ss") }, 
        until: { date: moment(month+'.'+day+'.'+year+' '+timeend).format("YYYY-MM-DD HH:mm:ss") } 
      })
      dispatch(setDate( month+'.'+day+'.'+year ));
    } else {
      setDay(day);
      setMonth(month);
      setYear(year);
    }
  }

  const handleTimeChange = (e) => {
    const target = e.target;
    let value = target.value;
    let formatedValue = value;
    let name = target.name;
    if (value.indexOf(':') === -1 ) {
      formatedValue += ':00';
    }
    if (name === 'timestart') {
      setEvent({ ...event, since: { date: moment(year+'-'+month+'-'+day+' '+formatedValue).format("YYYY-MM-DD HH:mm:ss") } });
      setTimestart(value);
    } else {
      setEvent({ ...event, until: { date: moment(year+'-'+month+'-'+day+' '+formatedValue).format("YYYY-MM-DD HH:mm:ss") } });
      setTimeend(value);
    }
  }

  const selectTerritory = (territory, index) => {
    let territories =  [...event.territory];
    if (event.territory && event.territory.length > 0) {
      let exists = false; 
      _.each(territories, (item) => {
        if ( territory.uuid === item.uuid ) {
          item.trash = !item.trash;
          exists = true;
        } else {
          item.trash = true;
        }
      })
      if(!exists) {
        territories.push({ trash: false, uuid: territory.uuid, ident: territory.ident})
      }
    } else {
      territories = [{ trash: false, uuid: territory.uuid, ident: territory.ident}];
    }
    setEvent({...event, territory: territories })
  }

  let inputBox = null;

  const icons = {
    'active': <ActiveIcon />,
    'new': <NewIcon />,
    'canceled': <CanceledIcon />,
    'ended': <EndedIcon />,
    'accepted': <ApprovedIcon />
  }

  const CalendarInput = ({ value, onClick }) => (
    <CalendarIcon className="icon-calendar"  onClick={onClick}/>
  );

  switch(activeInput) {
    case "eventName":
      inputBox = (
        <div className="input-box input-box-name">
          <h3>Dodaj nazwę eventu:</h3>
          <input type="text" name="name" value={ event.name } onChange={ (e)=>{ handleInputChange(e) } } placeholder="Nazwa eventu" />
          <h3>Ostatnio używane nazwy:</h3>
          <div className="buttons">
            <Button action={ ()=>{ setActiveInput('dateStart') } }>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "dateStart":
      const date = day ? moment(day + "-" + month + "-" + year, "DD-MM-YYYY").toDate() : moment().toDate()
      inputBox = (
        <div className="input-box input-box-date">
          <h3>Wybierz dzień:</h3>
          <div className="date-inputs">
            <input onChange={ this.handleDateChange } value={day} name="day" placeholder="dzień" />
            <input onChange={ this.handleDateChange } value={month} name="month" placeholder="miesiąc" />
            <input onChange={ this.handleDateChange } value={year} name="year" placeholder="rok" />
            <DatePicker
            selected={date}
            locale="pl"
            onChange={ (date) => {
              let dt = moment(date);
              setDay(dt.format("DD"));
              setMonth(dt.format("MM"));
              setYear(dt.format("YYYY"));
              setEvent({ 
                ...event, 
                since: { date: moment(dt.format("YYYY-MM-DD")+' '+timestart).format("YYYY-MM-DD HH:mm:ss") } 
              })
              dispatch(setDate( dt.format("YYYY-MM-DD") ))
            } }
            customInput={<CalendarInput/>}
          />
          </div>
          <div className="buttons">
          <Button action={ ()=>{ setActiveInput('dateEnd') } }>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "dateEnd":
      const dateEnd = day ? moment(day + "-" + month + "-" + year, "DD-MM-YYYY").toDate() : moment().toDate()
      inputBox = (
        <div className="input-box input-box-date">
          <h3>Wybierz dzień:</h3>
          <div className="date-inputs">
            <input onChange={ ()=>handleDateChange() } value={day} name="day" placeholder="dzień" />
            <input onChange={ ()=>handleDateChange() } value={month} name="month" placeholder="miesiąc" />
            <input onChange={ ()=>handleDateChange() } value={year} name="year" placeholder="rok" />
            <DatePicker
            selected={dateEnd}
            locale="pl"
            onChange={ (dateEnd) => {
              let dt = moment(dateEnd);
              setDay(dt.format("DD"));
              setMonth(dt.format("MM"));
              setYear(dt.format("YYYY"));
              setEvent({ ...event, until: { date: moment(dt.format("YYYY-MM-DD")+' '+timestart).format("YYYY-MM-DD HH:mm:ss") } })
              dispatch(setDate( dt.format("YYYY-MM-DD") ))
            } }
            customInput={<CalendarInput/>}
          />
          </div>
          <div className="buttons">
          <Button action={ ()=>{ setActiveInput('territories')  } }>Dalej</Button>
          </div>
        </div>
      )
      break;
    case "territory":
      const territories = []
      const filteredTerritories = this.props.territories.list.filter(createFilter(territoryFilter, ['ident']))

      inputBox = (
        <div className="input-box input-box-territory">
          <h3>Wybierz z listy:</h3>
          <SearchInput className="search-input" placeholder="Wyszukaj terytorium..." onChange={(filter) => {
            setTerritoryFilter(filter)
          }} />
          <ul>
            { filteredTerritories.map((item, i) => {
              return <li key={ i } className={ '' } onClick={ ()=>{ selectTerritory(item, i) } }>{ item.ident }</li>
            }) }
          </ul>
          <Button 
            action={ ()=>{ 
              event.new ? 
                dispatch(addEvent(event)) :
                dispatch(editEvent(event));
              hide(); } 
            }>Zapisz</Button>
        </div>
      )
      break;
    default:
      break;
  }

  return (
    <div className="add-edit-holder">
      <table className="add-edit-table">
        <tbody>
          <tr className={ "action-row new" }>
            <td></td>
            <td onClick={ ()=>{setActiveInput('eventName')}} className="action-name"><span>{ event.name === '' ? 'Nazwa Eventu' : event.name }</span></td>
            <td>{ event.ident }</td>
            <td onClick={ ()=>{this.setState({activeInput: "dateStart"})}}>{ event.since ? moment(event.since.date).format("D/M/Y") : "Data rozpoczęcia" }</td>
            <td onClick={ ()=>{this.setState({activeInput: "dateStart"})}}>{ event.until ? moment(event.until.date).format("D/M/Y") : "Data zakończenia" }</td>
            <td onClick={ ()=>{this.setState({activeInput: "territory"})}}>
              { event.territory ? event.territory.length > 0 ?
                event.territory.map((item, key) => {
                  if (!item.trash) {
                    return <p key={ key }>{ item.ident }</p>
                  }
                }) : 'Terytorium'
              : 'Terytorium'}
            </td>
            <td>
              <div className="buttons">
                <Button 
                  action={ ()=>{ 
                    event.new ? 
                      dispatch(addEvent(event)) :
                      dispatch(editEvent(event));
                    hide(); } 
                  }>Zapisz</Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      { inputBox }
    </div>
  );
}

export default AddEditEvent;