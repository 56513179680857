import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ReportItem from ".//ReportItem";
import { uploadReports, downloadReport } from '../../store/actions/reports';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '../../../img/calendar.svg';
import { API_URL } from '../../helpers/fetch';
import Dropdown from "../Dropdown";
import ReactPaginate from 'react-paginate';
import Export from '../modals/Export';
import ExportModal from '../modals/ExportModal';
import { getReports } from '../../store/reducers/reports';
import { getUser } from '../../store/reducers/user';
import PhotoModal from '../modals/PhotoModal';
import FilterModal from '../modals/FilterModal';
import Filter from '../modals/Filter';
import FilterReports from '../modals/FilterReports';

function ReportsPage() {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const reports = useSelector(getReports)
  const [since, setSince] = useState(moment().subtract(1, 'months'))
  const [until, setUntil] = useState(moment())
  const [exportPopup, setExportPopup] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [filterPopup, setFilterPopup] = useState(false);
  const [photoModal, setPhotoModal] = useState({
    open: false,
    imgUrl: ""
  })

  useEffect(() => {
    dispatch(uploadReports(since.format('YYYY-MM-DD'), until.format('YYYY-MM-DD')))
  }, [])


  const sinceDate = since.toDate();
  const untilDate = until.toDate();

  const CalendarInput = ({ value, onClick }) => (
    <CalendarIcon className="icon-calendar" onClick={onClick} />
  );

  const reportsList = reports.list.filter((item, key) => {
    if (item.status.global.ident === 'finished') {
      return true;
    }
  })

  return (
    <section className="actions reports">
      <div className="page-header actions-header">
        <h1>Raporty</h1>
        <a className="btn export-btn" onClick={() => setExportPopup(true)}>Eksportuj</a>
        <a className="btn export-btn" onClick={ ()=>{setFilterPopup(true)} } target="_blank">Filtruj</a>
        <div className="calendar-dropdown">
          <p className="selected" onClick={() => {
            setDropdown(!dropdown)
          }}>{since.format('YYYY.MM.DD') + ' - ' + until.format('YYYY.MM.DD')}</p>
          <div className={dropdown ? "month-dropdown dates-dropdown open" : "month-dropdown dates-dropdown"}>
            <div className="since-date">
              <span>od </span>
              <label>{since.format('YYYY.MM.DD')}</label>
              <DatePicker
                selected={sinceDate}
                locale="pl"
                popperPlacement="bottom-right"
                onChange={(sinceDate) => {
                  let dt = moment(sinceDate);
                  setSince(dt)
                  dispatch(uploadReports(dt.format('YYYY-MM-DD'), until.format('YYYY-MM-DD')))
                }}
                customInput={<CalendarInput />}
              />
            </div>
            <div className="since-date">
              <span>do </span>
              <label>{until.format('YYYY.MM.DD')}</label>
              <DatePicker
                selected={untilDate}
                locale="pl"
                popperPlacement="bottom-right"
                onChange={(untilDate) => {
                  let dt = moment(untilDate);
                  setUntil(dt)
                  dispatch(uploadReports(since.format('YYYY-MM-DD'), dt.format('YYYY-MM-DD')))
                }}
                customInput={<CalendarInput />}
              />
            </div>
          </div>
        </div>
      </div>
      <table className="actions-table">
        <thead>
          <tr>
            <td>Status</td>
            <td>Nr</td>
            <td>Nazwa</td>
            <td>Id</td>
            {user.role.uuid === "ASM" || user.role.uuid === "HSADM" ? <td>Terytorium</td> : null}
            <td>Event</td>
            <td>Lokalizacja</td>
            <td>Data</td>
            <td>Godzina</td>
            <td>Personel</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {reportsList.map((item, key) => {
            return (
              <ReportItem key={key} nr={key + 1} action={item} setPhotoModal={setPhotoModal}/>
            )
          })
          }
        </tbody>
      </table>
      <ExportModal
        open={exportPopup}
        hide={() => setExportPopup(false)} >
        <Export hide={() => setExportPopup(false)} finished={true} />
      </ExportModal>
      {reports.count > 100 ?
        <ReactPaginate
          previousLabel={'Poprzednia'}
          nextLabel={'Następna'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={reports.maxPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={(data) => { dispatch(uploadReports(since.format('YYYY-MM-DD'), until.format('YYYY-MM-DD'), data.selected)) }}
          containerClassName={'pagination'}
          activeClassName={'active'}
        /> : null}
      <PhotoModal open={photoModal.open} url={photoModal.imgUrl} setPhotoModal={setPhotoModal}/>
      <FilterModal 
        open={ filterPopup } 
        hide={ ()=>{setFilterPopup(false)} } >
          <FilterReports hide={ ()=>{setFilterPopup(false)} } />
      </FilterModal>
    </section>
  );
}

export default ReportsPage;