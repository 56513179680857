import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from '../../store/actions/personnel';
import Button from "../Button";
import _ from 'lodash';
import SearchInput, {createFilter} from '../SearchInput/SearchInput'
import SearchField from "../SearchField"
import "react-datepicker/dist/react-datepicker.css";
import { getPersonnel } from '../../store/reducers/personnel';
import { getActions } from '../../store/reducers/actions';

function FilterPersonnel({hide}) {
  const dispatch = useDispatch()
  const actions = useSelector(getActions);
  const personnel = useSelector(getPersonnel);
  const [territoryFilter, setTerritoryFilter] = useState(actions?.filter?.teritoryFilter || '')
  const [territoryFocus, setTerritoryFocus] = useState(false);

  const saveFilter = () => {
    dispatch(setFilter({
      territoryFilter: territoryFilter
    }));
    hide()
  }

  const clearFilter = () => {
    setTerritoryFilter('')
    dispatch(setFilter({
      territoryFilter: ''
    }))
  }

  const filteredTerritories = personnel.list.filter(createFilter(territoryFilter, ['territories.ident']))
  
  let territories = [];
  _.forEach(filteredTerritories, (item, k)=>{
    territories = [...territories, ...item.territories]
  })

  return (
    <div className="filter-wrap">
      <div className="filters">
        <div className="location-filters">
          <h3>Lokalizacja</h3>
          <SearchField value={territoryFilter} placeholder="Terytorium" onChangeAction={(filter) => { setTerritoryFilter(filter) }}>
              <ul className={ territoryFocus != '' ? 'active' : '' }>
                { _.uniqBy(territories, 'ident').map((item, i) => {
                  return <li key={ i } onMouseDown={()=>{setTerritoryFilter(item.ident)}} >{ item.ident }</li>
                }) }
              </ul>
            </SearchField>

        </div>
        <div className="utility-filters">
          
        </div>
      </div>
      <div className="buttons">
        <Button type="orange" action={ this.clearFilter }>Resetuj filtr</Button>
        <Button action={ this.setFilter }>Zapisz</Button>
      </div>
    </div>
  );
}

export default FilterPersonnel;