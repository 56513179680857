import React from "react"
import _ from 'lodash';
import { numberPrefixes } from "./data"
import { Checkbox, Form, Input, PhoneNumber, sendEvent, Submit } from "./Inputs"
import { otpSchema, userVerificationSchema } from "./schema"
import { useWebformStore } from "./store"
import CurrentStep from "./CurrentStep"
// import { selectActions } from '../../reducers/actions';
// import { useDispatch, useSelector } from 'react-redux';
import FixedButton from "./FixedButton";

export const VerifyUser = () => {

  const addDataToStore = useWebformStore(state => state.addDataToStore)
  const prevStep = useWebformStore(state => state.prevStep)
  const webformState = useWebformStore(state => state.webformState)
  const sendOtp = useWebformStore(state => state.sendOtp)
  const isLoading = useWebformStore(state => state.isLoading)
  const getAvailableList = useWebformStore(state => state.getAvailableList)
  // const actions = useSelector(selectActions);

  // const noOTPAction = _.filter(actions.list, (item) => item.status.global.ident === 'started' && item.name.indexOf('exp1707') > -1)[0] || false;
  const noOTPAction = false

  console.log(noOTPAction)

  if (webformState.step === 0) {
    return (
      <section className="form-section">
        <CurrentStep />
        <div className="verify-user">
          <Form schema={userVerificationSchema} onSubmit={data => { addDataToStore(data); sendOtp() }}
            defaultValues={{
              numberPrefix: numberPrefixes[0].value,
            }}>
            <Input
              name={"emailAddress"}
              placeholder={"np.jankowalski@gmail.com"}
              label={"E-mail"}
              onInputBlur={() => { sendEvent("input", "input", "EMAIL") }}
            />
            <PhoneNumber />
            <Checkbox
              name="inspirationClubConsent"
              label="Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do rejestracji w programie lojalnościowym Inspiration Club."
              onInputBlur={() => { sendEvent("checkbox", "click", "inspirationClubConsent") }}
            />
            <FixedButton>
              <Submit
                isLoading={isLoading}
                label="Wyślij kody"
              />
            </FixedButton>
          </Form>
        </div>
      </section>
    )
  }

  if (webformState.step === 1) {
    return (
      <section className="form-section">
        <CurrentStep />
        <div className="verify-user">
          <Form onSubmit={data => { addDataToStore(data); getAvailableList(noOTPAction ? false : true) }} schema={otpSchema}>
            <Input
              name={"otpEmail"}
              placeholder={"Podaj kod"}
              label={"Kod e-mail"}
              shouldUnregister={true}
            />
            <Input
              name={"otp"}
              placeholder={"Podaj kod"}
              label={"Kod sms"}
              shouldUnregister={true}
            />
            <p className="resend-otp">Kody nie dotarły?&nbsp;
              <a href="/" onClick={e => { e.preventDefault(); sendOtp(false) }}>
                Wyślij ponownie
              </a>
            </p>
            <FixedButton>
              <button className='btn white' onClick={prevStep}>
                Cofnij
              </button>
              <Submit
                isLoading={isLoading}
                label={"Zweryfikuj"}
              >
              </Submit>
              {noOTPAction && <Submit
                isLoading={isLoading}
                label={"Zweryfikuj bez OTP"}
              ></Submit>}
            </FixedButton>
          </Form>
        </div>
      </section>
    )
  }
}