import React from 'react';
import Button from "../Button";

function HSTCanceledInfo({hide}) {
  return (
    <div className="export-wrap">
      <div className="modal-info-inner">
        <p>Na Twojej liście znajdują się akcje odwołane przez Personel.<br/>
          Sprawdź statusy akcji i edytuj przypisane do nich osoby.</p>
        <Button action={ ()=>{ hide() }}>OK</Button>
      </div>
    </div>
  );
}

export default HSTCanceledInfo;