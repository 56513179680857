import { 
  UPLOAD_REPORTS,
  ADD_TOAST,
  DOWNLOAD_REPORT,
  SET_FILTER_REPORTS
} from './types';
import { API_URL, appFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';

export function setFilter(filter) {
  return (dispatch, getState) => { 
    console.error(getState())
    dispatch({ type: SET_FILTER_REPORTS, payload: filter });
    dispatch(uploadReports(getState().reports.since, getState().reports.until))
  }
}

export function uploadReports(since, until, page) {
  return (dispatch, getState) => { 
    const currentState = getState()

    console.warn(currentState.reports)

    let cities = currentState.reports.filter?.cityFilter ? {"cities": [currentState.reports.filter.cityFilter]} : {};
    let streets = currentState.reports.filter?.streetFilter ? {"streets": [currentState.reports.filter.streetFilter]} : {};
    let territories = currentState.reports.filter?.territoryFilter ? {"territories": { "uuids": [currentState.reports.filter.territoryFilterUUID]}} : {};
    let events = currentState.reports.filter?.eventFilter ? {"events": { "uuids": [currentState.reports.filter.eventFilterUUID]}} : {};
    let statuses = currentState.reports.filter?.statusFilter ? {"statuses": { "idents": [currentState.reports.filter.statusFilter]}} : {};
    let users = currentState.reports.filter?.personnelFilter ? {"users": { "uuids": [currentState.reports.filter.personnelFilterUUID]}} : {};
    // let idFilter = currentState.reports.filter?.idFilter ? {"id": { "uuids": [currentState.reports.filter.idFilter]}} : {};

    let filters = {...cities, ...streets, ...territories, ...events, ...statuses, ...users};
    
    const data = {
      "actions": {
        "since": since,
        "until": until,
        "finished": true,
        ...filters
      },
      "pagination": {
        "limit": 100,
        "offset": page*100||0
      }
    }
    appFetch("POST", API_URL, "/action/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: UPLOAD_REPORTS, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function downloadReport(since, until) {
  return (dispatch, getState) => { 
    const currentState = getState()

    appFetch("POST", API_URL, "/action/report/salesforce/"+since+"/"+until, {},currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: DOWNLOAD_REPORT, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}