import { 
  GET_EVENTS,
  ADD_TOAST
} from './types';
import { API_URL, appFetch } from '../../helpers/fetch'
import { errors } from '../../helpers/errors'

export function fetchEvents() {
  return (dispatch, getState) => { 

    const currentState = getState();
    const data = {
      pagination: {
        page: 0,
        pageSize: 500
      }
    }
    appFetch("POST", API_URL, "/event/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        if (result.data != null) {
          dispatch({ type: GET_EVENTS, payload: result.data });
        }
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function addEvent(event) {
  return (dispatch, getState) => { 

    const currentState = getState();
    const data = {
      event: {
        ident: event.ident,
        name: event.name,
        since: event.since,
        until: event.until,
        territory : event.territory
      }
    }
    appFetch("POST", API_URL, "/event/create", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        if (result.data != null) {
          dispatch(fetchEvents());
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Dodano nowy event" } });
        }
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })

  }
}

export function editEvent() {

}
