import React, { useState } from 'react'
import CurrentStep from "./CurrentStep"
import { useWebformStore } from './store';
import { Checkbox, Form, Input, Select, Submit } from './Inputs';
import FixedButton from './FixedButton';
import { offersDetails } from './data';
import { useWatch } from 'react-hook-form';
import { offerSchema } from './schema';

function Inputs({ props }) {
  const selectedModel = props.watch(`selectedOffers.${props.content.offer}.model`)

  if (!props.content.availableOptions) {
    return
  }

  return Object.keys(props.content.availableOptions).map(key => {
    const inputValue = props.content.availableOptions[key]

    switch (key) {
      case 'model':
        return <Select {...props} key={key} options={inputValue} name={`selectedOffers.${props.content.offer}.${key}`} shouldUnregister={true} label="Model" placeholder="Wybierz z listy" />
      case 'deviceName':
        return <Input {...props} key={key} name={`selectedOffers.${props.content.offer}.${key}`} label={"Nazwa urządzenia"} placeholder={"Nazwa urządzenia"} shouldUnregister={true} />
      case 'serialNumber':
        return <Input {...props} key={key} name={`selectedOffers.${props.content.offer}.${key}`} label={"my glo™ number"} placeholder={"Wpisz my glo™ number"} shouldUnregister={true} />
      case 'color':
        return <Select {...props} key={key} options={inputValue.get(selectedModel) ?? []} name={`selectedOffers.${props.content.offer}.${key}`} shouldUnregister={true} label={` ${selectedModel === "Vuse Go" ? "Smak" : "Kolor"}`} placeholder="Wybierz z listy" />
      case 'sampling':
        return <Select {...props} key={key} options={inputValue} name={`selectedOffers.${props.content.offer}.${key}`} shouldUnregister={true} label="Wybierz Velo" placeholder="Wybierz z listy" />
      case 'neoOffers':
        return <Select  {...props} key={key} options={inputValue} name={`selectedOffers.${props.content.offer}.${key}`} shouldUnregister={true} label="Wybierz ofertę neo" placeholder="Wybierz z listy" />
      case 'assetSource':
        return <Select {...props} key={key} options={inputValue.map(({ label, value, sku }, index) => {
          return {
            label,
            value: `${label}`,
            sku,
          }
        })} name={`selectedOffers.${props.content.offer}.${key}`} shouldUnregister={true} label="Źródło urządzenia" placeholder="Wybierz z listy" />
      default:
        return
    }
  })
}

function OfferTile(props) {
  let selected

  const webformState = useWebformStore(state => state.webformState)

  selected = props.selectedOffers.includes(props.content.offer)

  if (props.content.offer === "InspirationClub") {
    selected = props.watch(`selectedOffers.InspirationClub`)
  }

  // selected offers are placed in state
  function toggleSection(offer) {
    if (!props.selectedOffers.includes(offer)) {
      props.setSelectedOffers(prev => [...prev, offer])
    } else {
      props.setSelectedOffers(prev => prev.filter(e => e !== offer))
    }
  }


  // rewrite it

  const offersToDisableOnDevice = ["Vuse", "Glo", "Neo", "Glo_HUTD"]

  // custom messages because zod sucks
  const messages = {
    InspirationClub: "Zaznaczono tylko InspirationClub, wybierz dodatkową ofertę",
    Vuse: "Przy wybraniu oferty Vuse, oferta Velo jest wymagana",
    Neo: "Oferta Neo może jedynie łączyć się z ofertą InspirationClub"
  }

  let errorMessage = false
  let disabled = false
  let highlighted = false

  // allow selection of one device(glo or vuse)
  if (offersToDisableOnDevice.includes(props.content.offer) && !selected && offersToDisableOnDevice.some(e => props.selectedOffers.includes(e))) {
    disabled = true
  }

  // when neo offer selected disable rest of offers besides IC
  if (props.selectedOffers.includes("Neo") && !["InspirationClub", "Neo"].includes(props.content.offer)) {
    if (props.selectedOffers.includes("Velo") || props.selectedOffers.some(e => e.includes("Glo")) || props.selectedOffers.includes("Vuse")) {
      props.setSelectedOffers(["Neo"])
    }
    disabled = true
  }

  // force selection of velo when vuse selected by hightlighting
  if (props.selectedOffers.includes("Vuse") && props.content.offer === "Velo" && props.schemaErrors) {
    highlighted = true
  }

  // disable vuse offer when velo is not available 
  if (props.content.offer === "Vuse" && !webformState.data.Velo) {
    disabled = true
  }

  // force selection of velo when vuse selected by error message
  if (props.selectedOffers.includes("Vuse") && !props.selectedOffers.includes("Velo") && props.content.offer === "Vuse") {
    errorMessage = messages.Vuse
  }

  // disable neo on vuse offer
  if (props.selectedOffers.includes("Velo") && props.content.offer === "Neo") {
    disabled = true
  }

  // if only ic selected
  // if (props.selectedOffers.length === 0 && props.getValues().selectedOffers.InspirationClub === true && props.content.offer === "InspirationClub") {
  //   errorMessage = messages.InspirationClub
  // }

  if (props.selectedOffers.includes("Glo_HUTD") && props.content.offer === "Glo") {
    disabled = true
  }

  if (props.selectedOffers.includes("Glo") && props.content.offer === "Glo_HUTD") {
    disabled = true
  }

  // show ic offer only if glo selected
  if (!props.selectedOffers.some(e => e.includes("Glo")) && props.content.offer === "InspirationClub") {
    return
  }

  return (
    <div className={`webform-offer ${selected ? "selected" : ""} ${!props.content.available || disabled ? "disabled" : ""} ${highlighted ? "highlighted" : ""}`}>
      <div className='summary'>
        <img src={props.content.thumbnail} alt='' />
        <div className='text'>
          <h3>{props.content.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: props.content.desc }} />
          {errorMessage.length !== 0 && <h3 className='error'>{errorMessage}</h3>}
        </div>
        {props.content.offer === "InspirationClub" ?
          <Checkbox {...props} shouldUnregister={true} name={`selectedOffers.${props.content.offer}`} />
          :
          <button onClick={e => {
            e.preventDefault();

            // show ic offer if glo selected
            if (props.content.offer.includes("Glo") && props.icConsent) {
              // maybe add this to selected offers and remove watch on ic
              props.setValue("selectedOffers.InspirationClub", true)
            }

            toggleSection(props.content.offer)
          }}>
            <div className='checkbox' />
          </button>}
      </div>
      {selected && <div className='inputs-wrapper'>
        <Inputs props={props} />
      </div>}
    </div>
  )
}

export default function AvailableOffers() {
  const webformState = useWebformStore(state => state.webformState)
  const webformData = useWebformStore(state => state.webformData)

  const storeHasData = webformData?.selectedOffers
  const storeDefaultValues = storeHasData ? { selectedOffers: webformData?.selectedOffers } : {}
  const icConsent = webformState.data.InspirationClub

  const [selectedOffers, setSelectedOffers] = useState(storeHasData ? Object.keys(webformData.selectedOffers) : [])

  const addDataToStore = useWebformStore(state => state.addDataToStore)

  const prevStep = useWebformStore(state => state.prevStep)
  const nextStep = useWebformStore(state => state.nextStep)
  const setOfferChosen = useWebformStore(state => state.setOfferChosen)
  const setVerified = useWebformStore(state => state.setVerified)

  const sortedOffers = offersDetails.map(e => ({ ...e, available: webformState.data[e.offer] })).sort((a, b) => Number(b.available) - Number(a.available))

  return (
    <section className="select-offers-section">
      <CurrentStep />
      <Form defaultValues={storeDefaultValues} onSubmit={data => { addDataToStore(data); nextStep(); setOfferChosen(true) }} schema={offerSchema}>
        {sortedOffers.map((e, index) => <OfferTile key={index} content={e} icConsent={icConsent} selectedOffers={selectedOffers} setSelectedOffers={setSelectedOffers} />)}
        <FixedButton>
          <button className='btn white' onClick={() => { prevStep(); setVerified(false) }}>
            Cofnij
          </button>
          <Submit
            label={"Dalej"}
          >
          </Submit>
        </FixedButton>
      </Form>
    </section>
  )
}
