import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { changePass } from '../store/actions/user';
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import PopupModal from './modals/PopupModal';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg'
import { getUser } from '../store/reducers/user';

function UserDataPage() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [passPopup, setPassPopup] = useState(false);
  const [password, setPassword] = useState('');

  const handleChangePass = () => {
    const data = {
      user: {
        password: password
      }
    }

    dispatch(changePass(data));
    setPassPopup(false);
    setPassword('');
    this.setState({ passPopup: false, password: "" })
  }

  return (
    <section className="user-data">
      <NavLink to="/actions" className="back"><Arrow/></NavLink>
      <h1 className="title">Twoje dane</h1>
      <div className="user-data-content">
        <h3>ID</h3>
        <p>{ user?.ident }</p>
        <h3>Login</h3>
        <p>{ user?.username }</p>
        <h3>Imię i nazwisko</h3>
        <p>{ user?.firstname + " " + user?.lastname }</p>
        <h3>Terytoria</h3>
        <ul className="territories">
          { user?.territories?.map ((item, i) => {
              return <li key={ i }>{ item.ident }</li>
          }) }
        </ul>
        <h3>Adres e-mail</h3>
        <p>{ user?.emailAddress }</p>
        <h3>Numer telefonu</h3>
        <p>{ user?.mobilePhone }</p>
        <h3>Hasło</h3>
        <p className="pass"><span>********</span><button className="btn" onClick={ () => { this.setState({ passPopup: true }) } }>Zmień</button></p>
      </div>
      <PopupModal open={ passPopup } hidePopup={ ()=>{ setPassPopup(false) } }>
        <div className="popup-inner">
          <h3>Zmiana hasła</h3>
          <input type="password" placeholder="Wpisz nowe hasło" value={ password } onChange={ (e) => { setPassword(e.target.value) } }/>
          <button className="btn" onClick={ () => { handleChangePass() } }>Zmień hasło</button>
        </div>
      </PopupModal>
    </section>
  );
}

export default UserDataPage;